
import { Component, Prop, Vue } from "vue-property-decorator";
import { Action } from 'vuex-class';
import namespaces from '@/store/namespaces';
import ResponsiveNavItems from '@/ui-components/NavigationDrawer/ResponsiveNavItems/ResponsiveNavItems.vue';

@Component({
  name: "NavigationDrawer",
  components:{
    ResponsiveNavItems,
  },
})
export default class extends Vue {
  @Prop({ type: String, default: "246px" }) width!: string;
  @Prop({ type: Boolean, default: false }) bordered!: boolean;
  @Prop({ type: Boolean, default: true }) permanent!: boolean;
  @Prop({ type: Boolean, default: true }) floating!: boolean;
  @Prop({ type: Boolean, default: false }) absolute!: boolean;
  @Prop({ type: Boolean, default: false }) temporary!: boolean;
  @Prop({ type: Boolean, default: false }) miniVariant!: boolean;
  @Prop({ type: Boolean }) isOpen!: boolean;

  @Action("toggleSideMenu", { namespace: namespaces.UiModule })
  public toggleSideMenu() {
  }
  get isMobileViewPort() {
    return this.$vuetify.breakpoint.smAndDown;
  }

  targetIsNotOpenIcon(id:string){
    return id !== 'burger-menu'
  }
  closeSideMenu(e: any) {
    if (this.targetIsNotOpenIcon(e?.target?.id) && this.isOpen) {
      this.toggleSideMenu()
    }

  }
}
