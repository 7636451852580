import { getUserRoleValue, getUserRouteNames } from './../utils/user';
import { Route, RawLocation } from 'vue-router';
import { publicRouteNames } from '@/route-names/public';
import store from '@/store';
import { nextTo } from '@/utils/router';

/**
 * Used to navigate to '/dashboard' if the User already Logged in.
 */
export const publicPageGuard = async (to: Route, _: Route, next: (to?: RawLocation) => void) => {
  const continueTo = nextTo(next);
  const continueToLogin = continueTo({
    ...publicRouteNames.LOGIN,
  });

  try {
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
    const isShared = to.matched.some((record) => record.meta.shared);
    const isAuthenticated = store.getters['AuthModule/isAuthenticated'];

    if (isShared) {
      return next();
    }

    if ((!requiresAuth) && isAuthenticated) {
      const routeNames = store.getters['AuthModule/userRouteNames'];
      const continueToDashboard = continueTo({
        ...routeNames.DASHBOARD,
      });
      return continueToDashboard();
    }

    return next();
  } catch ({ message }) {
    continueToLogin();
    throw new Error(`On Public Page Guard, ${message}`);
  }
};
