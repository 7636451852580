
import DropdownMenu from "@/ui-components/DropdownMenu/DropdownMenu.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import { cloneDeep } from "lodash";
import NavButton from "../NavButton/NavButton.vue";
import ActionsList from "./components/ActionsList.vue";
import { AuthorizedUser } from "@/types/users";
import { DropDownAction } from "@/types/drop-down-actions";
import { publicRouteNames } from '@/route-names/public';

@Component({
  components: { DropdownMenu, ActionsList, NavButton },
  name: "UserMenuButton",
})
export default class UserMenuButton extends Vue {
  @Prop({ type: Array, default: () => ([[]]) })
  public dropDownActions!: DropDownAction[][];

  @Prop()
  public userInfo!: AuthorizedUser;

  get isCampaignWizardPage() {
    return this.$router.currentRoute.name === publicRouteNames.CREATE_CAMPAIGN.name;
  }

  get isMobileViewPort() {
    return this.$vuetify.breakpoint.mdAndDown;
  }

  get hideNavBarOnDashboard() {
    if (!this.isCampaignWizardPage) {
      return 'd-none'
    }
    return ''
  }
  public get avatar() {
    return '';
  }
  get dropDownActionsComputed(){
    if(this.isCampaignWizardPage && this.isMobileViewPort) {
      const namedDropdownMenu = cloneDeep(this.dropDownActions)
      namedDropdownMenu.unshift([{
        name: this.fullName,
        icon: '/assets/icons/user-o.svg',
      }])
      return namedDropdownMenu

    }
    return this.dropDownActions;
  }
  public get fullName() {
    const { NAME } = this.userInfo || {};
    return NAME;
  }
}
