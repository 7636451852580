import { Module } from 'vuex';
import { mutations } from './mutations';
import { actions } from './actions';
import { getters } from './getters';
import { RootState } from '../../types';
import { NavigationState } from './types';

const state: NavigationState = {
  sideNavItems: null,
  dropDownActions: null,
};

export const NavigationModule: Module<NavigationState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};