import { LocationData } from '@/types/locations';
import { FirebaseRemoteConfig } from '@/firebase/firebase-app';
import firebaseNames from '@/statics/firebase-names';
import { AdSpace } from '@/types/adspaces';

export const sortLocationsByPriorityOfAppearance = (locations: LocationData[]) => {
  return locations.sort((a, b) => {
    if (a.PRIORITY === undefined) return 1;
    if (b.PRIORITY === undefined) return -1;
    return a.PRIORITY - b.PRIORITY;
  });
}

export const getActiveStoresIds = async (): Promise<AdSpace['SPACE_OWNER_ID'][]> => {
  await FirebaseRemoteConfig.fetchAndActivate();
  const config = FirebaseRemoteConfig.getValue(firebaseNames.remoteConfig.ACTIVE_STORES_ON_PLATFORM)
  return Object.keys(JSON.parse(config.asString()))
}
export const checkShowOnlyActiveStoresFlag = async (): Promise<boolean> => {
  await FirebaseRemoteConfig.fetchAndActivate();
  return FirebaseRemoteConfig.getBoolean(firebaseNames.remoteConfig.SHOW_ONLY_ACTIVE_STORES_ON_PLATFORM);
}
