import { CommonState } from './types';
import { LocationData } from '@/types/locations';
import { MutationTree } from 'vuex';
import { Campaign } from '@/types/campaigns';

export const mutations: MutationTree<CommonState> = {
  setLocations: (state: CommonState, locations: LocationData[]) => {
    state.locations = locations;
  },
  setMyCampaigns: (state: CommonState, campaigns: Campaign[]) => {
    state.myCampaigns = campaigns;
  },
  resetAllData: (state: CommonState) => {
    state.myCampaigns = [];
    state.locations = [];
  },
};
