import { Base64, CampaignMedia, MediaFile, MediaType } from '@/types/media';
import {
  Campaign,
  CampaignCheckoutRequest, CampaignFrequency,
  CampaignRequest, CampaignWithSingleAdSpaceMedia,
  ChangeCampaignApproval,
  PendingPaymentCampaign,
  PlaylistCampaign,
  AdSpacePlaylistRequest,
  CreateStoreOwnerCampaign,
  PublishedCampaign,
  ShashaAd,
  StoreOwnerCampaign
} from '@/types/campaigns';
import firebase from 'firebase';
import { getCurrentUser } from '@/firebase/firebase-user';
import { FirebaseAppFirestore, FirebaseAppFunctions, } from '@/firebase/firebase-app';
import firebaseNames from '@/statics/firebase-names';
import { PaymentMetaData } from '@/types/payment';
import { CampaignWizardState } from '@/store/modules/campaign-wizard/types';
import moment from 'moment';
import { SYSTEM_STATUS } from '@/statics/system-status';
import { OnlyRequired } from '@/types/misc';
import { LocationData } from '@/types/locations';
import { v4 as uuidv4 } from 'uuid';
import { QuerySnapshot } from '@firebase/firestore-types';
import { AdSpacePriceControl } from '@/types/adspaces';
import { mapCampaignMediaToMediaFile, mediaNameFactory } from '@/utils/media';
import { flow } from 'lodash';
import { getUserInfoAction } from '@/actions/users/actions';
import { getUserRoleValue } from '@/utils/user';
import { USER_TYPES_ABBREVIATIONS } from '@/statics/user-roles';
import { Role } from '@/types/users';
import i18n from '@/plugins/i18n';

/**
 * @returns {string} Payment URL
 */
export const checkoutCampaign = async (
  campaignId: string,
  promoCode?: string
): Promise<PaymentMetaData> => {
  const currentUser = await getCurrentUser();
  const uid = currentUser!.uid;

  const checkoutCampaignFn = FirebaseAppFunctions.httpsCallable(
    firebaseNames.functions.CHECKOUT_CAMPAIGN
  );
  const request: CampaignCheckoutRequest = {
    campaignRequest: {
      ID: campaignId,
      ADVERTISER_UID: uid,
    },
    promoCode,
  };
  const { data } = await checkoutCampaignFn(request);
  return data;
};

/**
 * Update a campaign for a user.
 * @param campaign Input campaign
 */
export const updateUserCampaignAction = async (campaign: Campaign) => {
  const { ADVERTISER_UID: userId } = campaign;
  await FirebaseAppFirestore.collection(firebaseNames.CAMPAIGNS.VAL)
    .doc(userId)
    .collection(firebaseNames.CAMPAIGNS.USER_CAMPAIGNS)
    .doc(campaign.ID)
    .update(campaign);
};

/**
 * Delete campaign.
 * @param campaign Campaign
 */
export const deleteCampaignAction = async (
  campaign: Campaign
): Promise<void> => {
  const { ADVERTISER_UID: userId, ID: campaignId } = campaign;
  const campaignRef = FirebaseAppFirestore.collection(
    firebaseNames.CAMPAIGNS.VAL
  )
    .doc(userId)
    .collection(firebaseNames.CAMPAIGNS.USER_CAMPAIGNS)
    .doc(campaignId);
  return campaignRef.delete();
};

export const downloadInvoice = async (
  invoiceNumber: PublishedCampaign['INVOICE_NUMBER']
): Promise<Base64> => {
  const downloadInvoiceFn = FirebaseAppFunctions.httpsCallable(
    firebaseNames.functions.DOWNLOAD_INVOICE
  );
  const { data } = await downloadInvoiceFn(invoiceNumber);
  return data;
};

export const downloadEstimate = async (
  estimateNumber: PendingPaymentCampaign['ESTIMATE_NUMBER']
): Promise<Base64> => {
  const downloadEstimateFn = FirebaseAppFunctions.httpsCallable(
    firebaseNames.functions.DOWNLOAD_ESTIMATE
  );
  const { data } = await downloadEstimateFn(estimateNumber);
  return data;
};
const getCampaignPriceControl = (locations: LocationData[]): AdSpacePriceControl => {
  const isAllLocationsPriceControlByShasha = locations.every((location) => location.isPriceControlByShasha);

  return isAllLocationsPriceControlByShasha ? AdSpacePriceControl.BY_SHASHA : AdSpacePriceControl.BY_SPACE_OWNER
}
export const getAbbreviatedUserType = (roles: Role[]) => {

  const userRoleValue = getUserRoleValue(roles)
  return USER_TYPES_ABBREVIATIONS[userRoleValue]
}
const setMediaList = async (wizardState: CampaignWizardState, campaignData: Campaign | CreateStoreOwnerCampaign) => {
  const { ROLES, COMPANY_NAME } = await getUserInfoAction();
  const abbreviatedUserType = getAbbreviatedUserType(ROLES);
  if (wizardState.media?.files && wizardState.media?.files.length) {
    campaignData.MEDIA_LIST = wizardState.selectedLocations?.map(
      (location) => {
        const mediaForLocation = wizardState.media?.files?.find(
          (media) =>
            media?.width === location.SCREENS_RESOLUTION.WIDTH &&
            media.height === location.SCREENS_RESOLUTION.HEIGHT
        );
        return {
          MEDIA_FILE: mediaForLocation!.path!,
          MEDIA_TYPE: mediaForLocation!.mime! as MediaType,
          MEDIA_PLAYBACK_DURATION:
            mediaForLocation!.playBackDuration || null,
          MEDIA_UPLOADED_AT: firebase.firestore.Timestamp.now(),
          WIDTH: mediaForLocation?.width,
          HEIGHT: mediaForLocation?.height,
          AD_SPACE_ID: location.ID,
          MEDIA_ID: mediaForLocation?.mediaId,
          NAME: mediaNameFactory({
            abbreviatedUserType,
            companyName: COMPANY_NAME,
            mediaForLocation,
            campaignName: wizardState.name,
          }),
        };
      }
    );
  }
}
const campaignFactoryFromWizardState = async (
  wizardState: CampaignWizardState,
  campaignId: string
): Promise<Campaign> => {
  const currentUser = await getCurrentUser();

  const startDateMoment = moment(wizardState.time!.startDate!);
  const startDateTimeStamp = firebase.firestore.Timestamp.fromDate(
    startDateMoment.toDate()
  );
  const endDateMoment = startDateMoment.add(
    wizardState.time!.duration!,
    'weeks'
  );
  const endDateTimeStamp = firebase.firestore.Timestamp.fromDate(
    endDateMoment.toDate()
  );

  const campaignData: Campaign = {
    ID: campaignId,
    ADVERTISER_UID: currentUser!.uid,
    NAME: wizardState.name!,
    SCREEN_CLUSTERS_IDS: wizardState.selectedLocations!.map(
      (location) => location.clusterId
    ),
    START_DATE: startDateTimeStamp,
    END_DATE: endDateTimeStamp,
    DURATION_IN_WEEKS: Number(wizardState.time!.duration!),
    STATUS: SYSTEM_STATUS.DRAFT,
    FREQUENCY: wizardState.time!.frequency,
    PRICE_CONTROL: getCampaignPriceControl(wizardState.selectedLocations as LocationData[]),
  };

  if (wizardState.designerEmail) {
    campaignData.DESIGNER_EMAIL = wizardState.designerEmail;
    campaignData.DESIGNER_ACCESS_CODE = uuidv4()
    campaignData.SEND_UPLOAD_LINK_TO_DESIGNER = true
  }

  if (wizardState.media!.option === 'UPLOAD_NOW') {
    await setMediaList(wizardState, campaignData)
  } else if (wizardState.media!.option === 'UPLOAD_LATER') {
    campaignData.MEDIA_SKIPPED = true;
  }

  // TODO: validate/sanity-check campaign data in run-time
  if (
    !campaignData.SCREEN_CLUSTERS_IDS.length ||
    !campaignData.START_DATE ||
    !campaignData.END_DATE ||
    !campaignData.DURATION_IN_WEEKS
  ) {
    console.log('invalid campaign data', campaignData);
    throw new Error('invalid campaign data');
  }

  return campaignData;
};

const storeOwnerCampaignFactoryFromWizardState = async (
  wizardState: CampaignWizardState,
): Promise<CreateStoreOwnerCampaign> => {
  const startDate = firebase.firestore.Timestamp.fromDate(new Date(wizardState.time!.startDate!));
  const currentUser = await getCurrentUser();
  const campaignData: CreateStoreOwnerCampaign = {
    ADVERTISER_NAME: wizardState.advertiserName!,
    SCREEN_CLUSTERS_IDS: wizardState.selectedLocations!.map(
      (location) => location.clusterId
    ),
    STORE_OWNER_UID: currentUser!.uid,
    START_DATE: startDate,
    DURATION_IN_WEEKS: Number(wizardState.time!.duration!),
    FREQUENCY: wizardState.time?.frequency as CampaignFrequency,
    PRICE_CONTROL: getCampaignPriceControl(wizardState.selectedLocations as LocationData[]),
    isStoreOwner:true,
  };

  await setMediaList({
    ...wizardState,
    name: wizardState.advertiserName
  }, campaignData)

  // TODO: validate/sanity-check campaign data in run-time
  if (
    !campaignData.SCREEN_CLUSTERS_IDS.length ||
    !campaignData.START_DATE ||
    !campaignData.DURATION_IN_WEEKS
  ) {
    console.log('invalid campaign data', campaignData);
    throw new Error('invalid campaign data');
  }
  if (Object.values(wizardState.locationPrices || {})?.length) {
    campaignData.LOCATION_PRICES = wizardState.locationPrices;
  }
  return campaignData;
};

const getCampaignIdAndRef = async (
  savedCampaignId?: Campaign['ID']
): Promise<[Campaign['ID'], firebase.firestore.DocumentReference]> => {
  const currentUser = await getCurrentUser();

  const campaignCollection: firebase.firestore.CollectionReference<firebase.firestore.DocumentData> = FirebaseAppFirestore.collection(
    firebaseNames.CAMPAIGNS.VAL
  )
    .doc(currentUser!.uid)
    .collection(firebaseNames.CAMPAIGNS.USER_CAMPAIGNS);

  const campaignRef = savedCampaignId
    ? campaignCollection.doc(savedCampaignId)
    : campaignCollection.doc();

  const campaignId = savedCampaignId || campaignRef.id;

  return [campaignId, campaignRef];
};


/* Save campaign for current user.
 * Check for campaign, create, or update.
 * @param wizardState
 */
export const saveCampaign = async (
  wizardState: CampaignWizardState
): Promise<Campaign> => {
  const { savedCampaign = {} } = wizardState;
  const { ID: savedCampaignId } = savedCampaign as Campaign || {};
  const [campaignId, campaignRef] = await getCampaignIdAndRef(savedCampaignId);
  const campaignData = await campaignFactoryFromWizardState(
    wizardState,
    campaignId
  );

  await campaignRef.set(campaignData as Campaign);
  return campaignData;
};

export const saveStoreOwnerCampaign = async (
  wizardState: CampaignWizardState
): Promise<CreateStoreOwnerCampaign> => {
  const campaignData = await storeOwnerCampaignFactoryFromWizardState(wizardState);
  const createStoreOwnerCampaignFn = FirebaseAppFunctions
    .httpsCallable(firebaseNames.functions.CREATE_STORE_OWNER_CAMPAIGN);
  const { data } = await createStoreOwnerCampaignFn(campaignData);
  return { ...campaignData,ID:data.result };
};

/**
 * Update a campaign for current user.
 * @param campaign Input campaign
 */

export const updateCampaign = async (
  campaign: OnlyRequired<Campaign, 'ID'>
) => {
  const currentUser = await getCurrentUser();

  // TODO: validate/sanity-check campaign data in run-time
  if (!campaign.ID) {
    console.log('invalid campaign id', campaign);
    throw new Error('invalid campaign id');
  }

  await FirebaseAppFirestore.collection(firebaseNames.CAMPAIGNS.VAL)
    .doc(currentUser!.uid)
    .collection(firebaseNames.CAMPAIGNS.USER_CAMPAIGNS)
    .doc(campaign.ID)
    .update(campaign);
};

/**
 * Load a campaign by ID for current user.
 * @param campaignId - campaign ID
 */
export const loadCampaignById = async (
  campaignId: string
): Promise<Campaign> => {
  const currentUser = await getCurrentUser();
  const getCampaignFn = FirebaseAppFunctions.httpsCallable(
    firebaseNames.functions.GET_CAMPAIGN
  );
  const campaignRequest: CampaignRequest = {
    ID: campaignId,
    ADVERTISER_UID: currentUser!.uid,
  };
  const { data } = await getCampaignFn(campaignRequest);
  return data;
};

export const loadCampaignByIdAndUserId = async (
  campaignId: string,
  userId: string,
): Promise<Campaign> => {
  return FirebaseAppFirestore.collection(firebaseNames.CAMPAIGNS.VAL)
    .doc(userId)
    .collection(firebaseNames.CAMPAIGNS.USER_CAMPAIGNS)
    .doc(campaignId)
    .get().then((snapshot) => {
      return snapshot.data() as Campaign;
    });
};

export const loadCampaignLocations = async (
  screenClusterIds: string[],
): Promise<LocationData[]> => {
  const getAdSpacesByClustersIdsFunctions = FirebaseAppFunctions.httpsCallable(
    firebaseNames.functions.GET_ADSPACES_BY_CLUSTERS_IDS
  );
  const { data: { adSpaces } } = await getAdSpacesByClustersIdsFunctions({ clusterIds: screenClusterIds });
  return adSpaces;
};
// TODO: reduce params number
export const UpdateCampaignMediaList = (
  userId: string,
  campaignId: string,
  invalidateDesignerAccessCode: boolean,
  mediaList: CampaignMedia[],
  shouldResetCampaignApproval?: ChangeCampaignApproval,
): Promise<void> => {
  const campaignRef = FirebaseAppFirestore.collection(
    firebaseNames.CAMPAIGNS.VAL
  )
    .doc(userId)
    .collection(firebaseNames.CAMPAIGNS.USER_CAMPAIGNS)
    .doc(campaignId);
  return campaignRef.update({
    MEDIA_LIST: mediaList,
    ...invalidateDesignerAccessCode && { DESIGNER_ACCESS_CODE: '' },
    MEDIA_SKIPPED: false,
    ...shouldResetCampaignApproval,
  })
};

export const updateCampaignDesignerEmail = (
  userId: string,
  campaignId: string,
  designerEmail: string,
): Promise<void> => {
  const campaignRef = FirebaseAppFirestore.collection(
    firebaseNames.CAMPAIGNS.VAL
  )
    .doc(userId)
    .collection(firebaseNames.CAMPAIGNS.USER_CAMPAIGNS)
    .doc(campaignId);
  return campaignRef.update({
    DESIGNER_ACCESS_CODE: uuidv4(),
    DESIGNER_EMAIL: designerEmail,
    SEND_UPLOAD_LINK_TO_DESIGNER: true,
  })
};

export const findCampaignByName = async (user: firebase.User, campaignName: string, id: string): Promise<QuerySnapshot> => {
  return FirebaseAppFirestore
    .collection(firebaseNames.CAMPAIGNS.VAL)
    .doc(user.uid)
    .collection(firebaseNames.CAMPAIGNS.USER_CAMPAIGNS)
    .where('NAME', '==', campaignName)
    .where('ID', '!=', id)
    .get();
};

export const isUniqueCampaignName = async (campaignName: string, id: string): Promise<boolean> => {
  const user = await getCurrentUser();

  if (!user) {
    return true;
  }
  const documentSnap = await findCampaignByName(user, campaignName, id);
  return documentSnap.empty;
};

export const getAdSpacePlaylist = async ({
  adSpaceId,
  selectedDate,
  sortByFrequency
}: AdSpacePlaylistRequest): Promise<PlaylistCampaign[]> => {
  const getAdSpacePlaylistSortedFn = FirebaseAppFunctions
    .httpsCallable(firebaseNames.functions.GET_ADSPACE_PLAYLIST);
  const playlistRequest: AdSpacePlaylistRequest = {
    adSpaceId,
    selectedDate,
    sortByFrequency,
    isUserView: true,
  };
  const { data } = await getAdSpacePlaylistSortedFn(playlistRequest);

  return data.map((playlistItem: PlaylistCampaign) => {
    if ((playlistItem as CampaignWithSingleAdSpaceMedia)?.MEDIA_LIST) {
      flow(
        () => (playlistItem as CampaignWithSingleAdSpaceMedia).MEDIA_LIST
          ?.find((media) =>
            media.AD_SPACE_ID === adSpaceId),
        (campaignMedia: CampaignMedia) => campaignMedia && mapCampaignMediaToMediaFile(campaignMedia),
        (mediaFile: MediaFile) => playlistItem.mediaFile = mediaFile,
      )()
    } else if ((playlistItem as ShashaAd).MEDIA_FILE) {
      (playlistItem as ShashaAd).mediaFile = mapCampaignMediaToMediaFile(((playlistItem as ShashaAd).MEDIA_FILE) as CampaignMedia);
    }

    if((playlistItem as ShashaAd).ADMIN_UID ){
      (playlistItem as ShashaAd).advertiserName = i18n.t('shasha').toString() as 'Shasha';
      (playlistItem as ShashaAd).isShashaAd = true;
    }

    else if ((playlistItem as StoreOwnerCampaign).STORE_OWNER_UID) {
      (playlistItem as StoreOwnerCampaign).advertiserName = (playlistItem as StoreOwnerCampaign).ADVERTISER_NAME;
      (playlistItem as StoreOwnerCampaign).isStoreOwner = true;
    } else {
      (playlistItem as StoreOwnerCampaign).advertiserName = (playlistItem as Campaign).NAME;
    }
    return playlistItem
  });
};
