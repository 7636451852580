import { CampaignFrequency } from '@/types/campaigns';

export const VAT_PERCENTAGE = 15;

export const calcVatOn = (price: number): number => {
  const vat = VAT_PERCENTAGE / 100;
  return Math.round(price * vat);
};

export const addVatTo = (price: number): number => {
  const vatIncrease = calcVatOn(price);
  return price + vatIncrease;
};

export const ceilTo10 = (price: number): number => {
  return Math.ceil(price / 10) * 10;
}

export const currency = (value: number) => {
  if (typeof value !== 'number' || !value) {
    return value;
  }

  const formatter = new Intl.NumberFormat();

  return formatter.format(value);
};

export const creditCardExpiryDate = (value: string) => {
  if (value) {
    const cleanValue = value.replace(/\//g, '');
    const month = cleanValue.substring(0, 2);
    const year = cleanValue.substring(2, 4);

    return cleanValue.length > 2 ? `${month}/${year}` : cleanValue;
  }
  return value;
};

export function toNumberOr(
  value: string | number | undefined,
  defaultValue: number
): number {
  const num = Number(value);
  return Number.isNaN(num) || value === undefined ? defaultValue : num;
}

export const clockifyNumber = (number: number) => {
  const positiveOnlyNumber = number > 0 ? number : 0;
  const stringFromNumber = String(positiveOnlyNumber);

  if (stringFromNumber.length >= 2) {
    return stringFromNumber;
  } else {
    return `0${stringFromNumber}`;
  }
};

export const priceByFrequency = (price: number, frequency: CampaignFrequency): number => {
  return ceilTo10(price * frequency.COST_FACTOR);
}
