import { Discount } from '@/types/promotions';
import { PromoCodeData } from '@/types/promotions';
import { Campaign } from '@/types/campaigns';
import { MediaData } from '@/types/media';
import { PaymentData } from '@/types/payment';
import { TimeSlotSelection } from '@/types/time-slots';
import { LocationData, LocationPrice } from '@/types/locations';
import { GetterTree } from 'vuex';
import { RootState } from '../../types';
import { CampaignWizardState } from './types';

export const getters: GetterTree<CampaignWizardState, RootState> = {
  active: (state: CampaignWizardState): boolean => {
    return state.active;
  },
  selectedLocations: (state: CampaignWizardState): LocationData[] => {
    return state.selectedLocations!;
  },
  time: (state: CampaignWizardState): TimeSlotSelection => {
    return state.time!;
  },
  payment: (state: CampaignWizardState): PaymentData => {
    return state.payment!;
  },
  discounts: ({ payment = {} as PaymentData }: CampaignWizardState): Discount[] => {
    return payment.discounts || [];
  },
  media: (state: CampaignWizardState): MediaData => {
    return state.media!;
  },
  name: (state: CampaignWizardState): string => {
    return state.name!;
  },
  advertiserName: (state: CampaignWizardState): string => {
    return state.advertiserName!;
  },
  step: (state: CampaignWizardState): number => {
    return state.step!;
  },
  savedCampaign: (state: CampaignWizardState): Campaign => {
    return state.savedCampaign!;
  },
  promoCode: (state: CampaignWizardState): PromoCodeData => {
    return state.promoCode!;
  },
  designerEmail: (state: CampaignWizardState): string => {
    return state.designerEmail!;
  },
  locationPrices: (state: CampaignWizardState): Record<LocationData['ID'],LocationPrice> => {
    return state.locationPrices!;
  },
};
