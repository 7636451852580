
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from 'vuex-class';
import namespaces from '@/store/namespaces';
import { AuthorizedUser } from '@/types/users';
import Logo from '@/ui-components/Logo/Logo.vue';
@Component({
  name: "ResponsiveNavItems",
  components:{
    Logo,
  }
})
export default class ResponsiveNavItems extends Vue {
  @Getter("userInfo", { namespace: namespaces.AuthModule })
  public userInfo!: AuthorizedUser;

  @Action("toggleSideMenu", { namespace: namespaces.UiModule })
  public toggleSideMenu() {
  }
  public get fullName() {
    const { NAME } = this.userInfo || {};
    return NAME;
  }
}
