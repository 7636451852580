export default {
  UiModule: 'UiModule',
  AuthModule: 'AuthModule',
  AdvertiserModule: 'AdvertiserModule',
  NavigationModule: 'NavigationModule',
  CampaignWizardModule: 'CampaignWizardModule',
  CommonModule: 'CommonModule',
  CampaignModule: 'CampaignModule',
  StoreModule: 'StoreModule',
  FinancialModule: 'FinancialModule',
  IncomingCampaignsModule: 'IncomingCampaignsModule',
};
