import { Callback, DeferredPromise, Nullable } from '@/types/misc';
import { memoize, take, chain, identity, isEqual} from 'lodash';
import { cloneDeep, negate } from 'lodash/fp';
import moment from 'moment';

export function filterRecordByKeys<T extends Record<string, U>, U>(
  record: T,
  keys: string[]
) {
  const clonedRecord = cloneDeep(record);
  const includedKeys = Object.keys(clonedRecord).filter((key) =>
    keys.includes(key)
  );
  return includedKeys.reduce((filteredRecord, key) => {
    return { ...filteredRecord, [key]: clonedRecord[key] };
  }, {} as T);
}

export function isNonNullable<T>(val: Nullable<T>): val is NonNullable<T> {
  return Boolean(val);
}

export const isDatePassed = (date: string): boolean => {
  try {
    const now = moment();
    const momentDate = moment(date);
    return now.diff(momentDate, 'seconds') >= 0;
  } catch (error) {
    return false;
  }
};

export const sliceBy = <T>(
  array: T[],
  count: number,
  predicate: (elem: T) => boolean
) => {
  const filteredPart = array.filter(predicate);
  return take(filteredPart, count);
};

export const concatRecordValues = <T extends string, K>(
  record: Record<T, K | K[]>
): K[] =>
  Object.values<K | K[]>(record).reduce(
    (recordValues: K[], value: K | K[]) => recordValues.concat(value),
    []
  );

export function cacheKeyResolver(...args: any[]) {
  return JSON.stringify(args);
}

export const concatRecordValuesMemoized: <T extends string, K>(
  record: Record<T, K | K[]>
) => K[] = memoize(concatRecordValues, cacheKeyResolver);

export const Deferred = (): DeferredPromise => ({
  get promise() {
    return new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  },
});

/** Removes duplicates from Array */
export const uniqueArray = <T, K>(arr: T[], mapper: Callback<T, K> = identity): K[] =>
  chain(arr)
    .map(mapper)
    .uniqWith(isEqual)
    .value();
  // [...arr.reduce((uniqueMap, el) => {
  //   const mappedEl = mapper ? mapper(el) : el;
  //   return uniqueMap.set(JSON.stringify(mappedEl), mappedEl);
  // }
  // , new Map()).values()]

export const transduce = <A, B, C>(
  arr: A[],
  mapper: Callback<A, B>,
  reducer: (accumulator: C, element: B) => C,
  accumulator: C,
): C => arr.reduce((acc, el) => reducer(acc, mapper(el)), accumulator);

export const transduceToRecord = <A, B>(
  keyMapper: (a: A) => string,
  valueMapper: (a: A) => B
) => (acc: Record<string, B>, a: A): Record<string, B> => ({ ...acc, [keyMapper(a)]: valueMapper(a) })

export const not = negate;

export const call = <T, F extends (...args: any[]) => T>
  (...args: any[]) => (fn: F) => fn(...args);

export const xorCondition = (firstCondition: unknown, secondCondition: unknown): boolean=>{
  // @ts-ignore
  // tslint:disable-next-line:no-bitwise
  return Boolean(Boolean(firstCondition) ^ Boolean(secondCondition))
}
