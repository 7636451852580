
import { Component, Prop, Vue } from "vue-property-decorator";
import { DropDownAction } from "@/types/drop-down-actions";
import Divider from "@/ui-components/Divider/Divider.vue";

@Component({
  name: "ActionsList",
  components: {
    Divider
  }
})
export default class ActionsList extends Vue {
  @Prop({ type: Array, default: () => [] })
  public dropDownActions!: DropDownAction[][];

  public handleClick({ action, route }: DropDownAction) {
    if (route) {
      return this.$router.push(route);
    }

    if (action) {
      return action();
    }
  }

  public hasDivider(categoryIndex: number): boolean {
    return categoryIndex < this.dropDownActions.length - 1;
  }
}
