import { FirebaseAppFirestore } from '@/firebase/firebase-app';
import firebaseNames from '@/statics/firebase-names';
import { AdSpace } from '@/types/adspaces';
import { getActiveStoresIds } from '@/utils/locations';
import { getCurrentUser } from '@/firebase/firebase-user';
import { locationDataFactory } from '@/store/modules/common-module/actions';
import { chunk, flow, uniqBy, map, flatten } from 'lodash';
import { AuthorizedUser } from '@/types/users';
import { head } from 'lodash';
import { StoreOwnerLocationData } from '@/types/locations';

/**
 * Get all Ad Spaces info
 */
export const getAllClusteredAdSpaces = async (): Promise<AdSpace[]> => {
  const collectionRef = FirebaseAppFirestore
    .collection(firebaseNames.AD_SPACES)
    .orderBy('SCREEN_CLUSTER_REF');

  const adSpacesSnap = await collectionRef.get()

  if (adSpacesSnap.empty) {
    return [];
  }

 return adSpacesSnap.docs.map((doc) => doc.data() as AdSpace);
};
/**
 * Get screen cluster refs from regulatorId
 */
export const getAdSpaceByRegulatorId = async (regulatorId: AuthorizedUser['UID']): Promise<AdSpace|undefined> => {
  const collectionRef = FirebaseAppFirestore
    .collection(firebaseNames.AD_SPACES)
    .where('REGULATOR_ID', '==', regulatorId)

  const adSpacesSnap = await collectionRef.get()

  if (adSpacesSnap.empty) {
    return;
  }
 return head(adSpacesSnap.docs.map((doc) => doc.data() as AdSpace));
};

export const getClusteredAdSpacesByStoreIds = async (spaceOwnersIds: (AdSpace['SPACE_OWNER_ID'])[]): Promise<AdSpace[]> => {
  const collectionRef = FirebaseAppFirestore
    .collection(firebaseNames.AD_SPACES)
    .where('SPACE_OWNER_ID', 'in', spaceOwnersIds)
    .orderBy('SCREEN_CLUSTER_REF');

  const adSpacesSnap = await collectionRef.get()

  if (adSpacesSnap.empty) {
    return [];
  }

  return adSpacesSnap.docs.map((doc) => doc.data() as AdSpace);
}
export const getActiveClusteredAdSpaces = async (): Promise<AdSpace[]> => {
  const storeOwnersIds = await getActiveStoresIds();
  return getClusteredAdSpacesByStoreIds(storeOwnersIds);
};
export const loadStoreOwnerLocationsAction = async () => {
  const currentUser = await getCurrentUser();
  const collectionRef = FirebaseAppFirestore
    .collection(firebaseNames.AD_SPACES)
    .where('SPACE_OWNER_ID', '==', currentUser?.uid || '')
    .orderBy('SCREEN_CLUSTER_REF');

  const adSpacesSnap = await collectionRef.get()

  if (adSpacesSnap.empty) {
    return [];
  }
  const adSpaces = adSpacesSnap.docs.map((doc) => doc.data() as AdSpace);
  const regulators = flow((adSpacesData) => adSpacesData.filter((adSpace: AdSpace) => adSpace.REGULATOR_ID),
    (adSpacesWithRegulators) => uniqBy(adSpacesWithRegulators, 'REGULATOR_ID'),
    (uniqueAdSpacesWithRegulators: AdSpace[]) => uniqueAdSpacesWithRegulators.map((adSpace) => adSpace.REGULATOR_ID))
  (adSpaces as AdSpace[]);

  const regulatorNames = await flow(
    (regulatorsIds) => chunk(regulatorsIds, 10),
    (chunkedRegulatorsIds) => {
      return map(chunkedRegulatorsIds, (filterChunk) =>
        FirebaseAppFirestore
          .collection(firebaseNames.REGULATORS)
          .where('UID', 'in', filterChunk)
          .get())
    },
    async (snapshots) => {
      const allData = await Promise.all(snapshots)
      const regulatorDataArray = flatten(allData.map((snapshot) => snapshot.docs.map((doc) => doc.data() as AuthorizedUser)));
      return regulatorDataArray.reduce((regulatorMap, regulator) => ({
        ...regulatorMap,
        [regulator.UID]: regulator.NAME
      }), {} as Record<AuthorizedUser['UID'], AuthorizedUser['NAME']>)
    }
  )(regulators)
  const locationsData = locationDataFactory(adSpaces);
  return map(locationsData, (locationData) => ({
    ...locationData,
    regulatorName: regulatorNames?.[locationData?.REGULATOR_ID || ''] || ''
  })) as StoreOwnerLocationData[]
}
