import { StoreCampaign } from '@/types/campaigns';
import { getStoreScreenClusters } from '@/actions/campaigns/store-owner';
import { StoreState } from './types';
import { RootState } from '@/store/types';
import { ActionTree, ActionContext } from 'vuex';
import { getRegulatorScreenClusters } from '@/actions/campaigns/regulator';

export const actions: ActionTree<StoreState, RootState> = {
  setStoreCampaigns: async ({ commit }: ActionContext<StoreState, RootState>, campaigns: StoreCampaign[]) => {
    commit('setStoreCampaigns', campaigns);
  },
  getStoreCampaignById: async ({ state: { storeCampaigns } }: ActionContext<StoreState, RootState>, campaignId: string) => {
    return storeCampaigns.find(({ campaignRequest: { ID } }) => ID === campaignId);
  },
  loadStoreScreenClusters: async ({ commit }: ActionContext<StoreState, RootState>) => {
    const screenClusters = await getStoreScreenClusters();
    commit('setStoreScreenClusters', screenClusters);
  },
  loadRegulatorScreenClusters: async ({ commit }: ActionContext<StoreState, RootState>) => {
    const screenClusters = await getRegulatorScreenClusters();
    commit('setStoreScreenClusters', screenClusters);
  },
};
