import Vue from 'vue';
import { VueMaskDirective } from 'v-mask'
import App from './App.vue';
import VueRouter from 'vue-router';
import store from './store';
import vuetify from '@/plugins/vuetify';
import i18n from '@/plugins/i18n';
import router from '@/plugins/router';
import './filters';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import '@mdi/font/css/materialdesignicons.min.css';

import { InlineSvgPlugin } from 'vue-inline-svg';

Vue.config.productionTip = false;

Vue.use(VueRouter)
Vue.use(InlineSvgPlugin);
Vue.directive('mask', VueMaskDirective);

new Vue({
  router,
  vuetify,
  store,
  i18n,
  render(h: any) {
    return h(App, {
      props: {},
    });
  },
}).$mount('#app');
