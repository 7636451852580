import { Route, RawLocation } from 'vue-router';
import { publicRouteNames } from '@/route-names/public';
import store from '@/store';
import { getUserRoleValue } from '@/utils/user';
import { nextTo } from '@/utils/router';

/**
 * Guard routes against different user roles
 */
export const roleGuard = async (
  to: Route,
  _: Route,
  next: (to?: RawLocation) => void
) => {
  const continueTo = nextTo(next);

  try {
    const isAuthenticated = store.getters['AuthModule/isAuthenticated'];
    const routeRole = to.meta?.routeRole || '';

    if (!routeRole) {
      return next();
    }

    if (isAuthenticated) {
      const userRoles = store.getters['AuthModule/userRoles'];
      const routeNames = store.getters['AuthModule/userRouteNames'];
      const userRole = getUserRoleValue(userRoles);
      const continueToDashboard = continueTo({
        ...routeNames.DASHBOARD,
      });

      if (routeRole === userRole) {
        return next();
      }

      continueToDashboard();
    }

    next();
  } catch ({ message }) {
    next({
      ...publicRouteNames.LOGIN,
    });
    throw new Error(`On Role Guard, ${message}`);
  }
};
