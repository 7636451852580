import { TransactionHistory } from '@/types/financial';

export const transactionsHistory: TransactionHistory[] = [
  {
    quarterNumber: 'first',
    quarterYear: '2021',
    amount: 50000,
    startDate: new Date().toISOString(),
    endDate: new Date().toISOString(),
    file: {
      name: 'file.pdf',
      path: 'http://www.africau.edu/images/default/sample.pdf'
    },
  },
  {
    quarterNumber: 'second',
    quarterYear: '2021',
    amount: 50000,
    startDate: new Date().toISOString(),
    endDate: new Date().toISOString(),
    file: {
      name: 'file1.pdf',
      path: 'http://www.africau.edu/images/default/sample.pdf'
    },
  },
];