import store from '@/store';
import { FirebaseAppAuth } from './firebase-app';
import firebase from 'firebase';

export const authStateWatcher = () => {
  return FirebaseAppAuth.onAuthStateChanged((user) => {
    if (!user) {
      return store.dispatch('AuthModule/logout')
        .catch(err => {
          console.log(err)
        });
    }

    store.commit('AuthModule/setUser', user);
  });
}

export const getCurrentUser = async (): Promise<firebase.User | null> => {
  return new Promise((resolve, reject) => {
    try {
      const authStateHandler = FirebaseAppAuth.onAuthStateChanged((user) => {
        resolve(user);
        authStateHandler && authStateHandler();
      });
    } catch (error) {
      reject(error);
    }
  });
};
