import { DropDownAction } from '@/types/drop-down-actions';
import {
  advertiserDropdownActions,
  regulatorDropdownActions,
  storeOwnerDropdownActions
} from './user-drop-down-actions';

export const userDropDownActionsMap: {
  [key: string]: DropDownAction[][]
} = {
  advertiser: advertiserDropdownActions,
  'store-owner': storeOwnerDropdownActions,
  regulator: regulatorDropdownActions,
};
