import { GetterTree } from 'vuex';
import { RootState } from '../../types';
import { CampaignState } from './types';
import { cloneDeep } from 'lodash/fp';
import firebase from 'firebase';
import firestore = firebase.firestore;
import { ClustersSchedulesRecord } from '@/types/screen-cluster';

export const getters: GetterTree<CampaignState, RootState> = {
  getCurrentStoreCampaignRef(state: CampaignState): firestore.DocumentReference {
    return state.currentStoreCampaignRef!;
  },
  getSelectedClustersSchedules(state: CampaignState): ClustersSchedulesRecord {
    const { selectedClusterId, selectedSchedulesInCluster } = state;

    if (selectedSchedulesInCluster.length) {
      state.selectedClustersSchedules[selectedClusterId] = cloneDeep(selectedSchedulesInCluster);
    } else {
      delete state.selectedClustersSchedules[selectedClusterId];
    }

    return state.selectedClustersSchedules;
  },
};
