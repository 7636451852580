import { Module } from 'vuex';
import { mutations } from './mutations';
import { getters } from './getters';
import { RootState } from '../../types';
import { UiState } from './types';
import { actions } from './actions';

const state: UiState = {
  isSideMenuOpen: true,
  isSideMenuCollapsed: false,
  snackbar: {
    visible: false,
    text: '',
    color: 'danger',
    timeout: 5000,
  },
};

export const UiModule: Module<UiState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
