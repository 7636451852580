import { storeOwnerRouteNames } from '@/route-names/store-owner';

const StoreOwnerLayout = () => import('@/layouts/StoreOwnerLayout/StoreOwnerLayout.vue');
const DashboardPage = () => import('@/pages/store-owner/Dashboard/Dashboard.vue');
const IncomingCampaignsPage = () => import('@/pages/store-owner/IncomingCampaigns/IncomingCampaigns.vue');
const PlaylistPage = () => import('@/pages/store-owner/Playlist/Playlist.vue');
const CreateStoreOwnerCampaignPage = () => import('@/pages/store-owner/CreateCampaignWizard/CreateCampaignWizard.vue');
const ManageMyAdsPage = () => import('@/pages/store-owner/ManageMyAds/ManageMyAds.vue');
const FinancialPage = () => import('@/pages/store-owner/Financial/Financial.vue');
const ActionsCenterPage = () => import('@/pages/store-owner/ActionsCenter/ActionsCenter.vue');
const BranchesPage = () => import('@/pages/store-owner/Branches/Branches.vue');
const CompanyProfilePage = () => import('@/pages/store-owner/CompanyProfile/CompanyProfile.vue');
const HelpPage = () => import('@/pages/store-owner/Help/Help.vue');
const ShareFeedbackPage = () => import('@/pages/store-owner/ShareFeedback/ShareFeedback.vue');
const CampaignViewPage = () => import('@/pages/store-owner/CampaignView/CampaignView.vue');

export const storeOwnerRoutes = [
  {
    path: '/store-owner',
    component: StoreOwnerLayout,
    meta: {
      requiresAuth: true,
      routeRole: 'store-owner'
    },
    children: [
      // {
      //   ...storeOwnerRouteNames.DASHBOARD,
      //   path: 'dashboard',
      //   component: DashboardPage,
      //   children: [],
      // },
      {
        ...storeOwnerRouteNames.INCOMING_CAMPAIGNS,
        path: 'incoming-campaigns',
        component: IncomingCampaignsPage,
        children: [],
      },{
        ...storeOwnerRouteNames.PLAYLIST,
        path: 'playlist',
        component: PlaylistPage,
        children: [],
      },{
        ...storeOwnerRouteNames.CREATE_STORE_OWNER_CAMPAIGN,
        path: 'create-store-owner-campaign',
        component: CreateStoreOwnerCampaignPage,
        children: [],
      },
      {
        ...storeOwnerRouteNames.INCOMING_CAMPAIGN_VIEW,
        path: 'incoming-campaigns/campaign/:id',
        component: CampaignViewPage,
        children: [],
      },
      {
        ...storeOwnerRouteNames.MANAGE_MY_ADS,
        path: 'manage-my-ads',
        component: ManageMyAdsPage,
        children: [],
      },
      {
        ...storeOwnerRouteNames.FINANCIAL,
        path: 'financial',
        component: FinancialPage,
        children: [],
      },
      {
        ...storeOwnerRouteNames.ACTIONS_CENTER,
        path: 'actions-center',
        component: ActionsCenterPage,
        children: [],
      },
      {
        ...storeOwnerRouteNames.BRANCHES,
        path: 'branches',
        component: BranchesPage,
        children: [],
      },
      {
        ...storeOwnerRouteNames.COMPANY_PROFILE,
        path: 'company-profile',
        component: CompanyProfilePage,
        children: [],
      },
      {
        ...storeOwnerRouteNames.HELP,
        path: 'help',
        component: HelpPage,
        children: [],
      },
      {
        ...storeOwnerRouteNames.SHARE_FEEDBACK,
        path: 'share-feedback',
        component: ShareFeedbackPage,
        children: [],
      },
    ]
  },
];
