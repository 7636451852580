
import { Component, Vue } from "vue-property-decorator";
import NavButtons from "@/ui-components/NavButtons/NavButtons.vue";
import UserMenuButton from '@/ui-components/UserMenuButton/UserMenuButton.vue';
import namespaces from "@/store/namespaces";
import { DropDownAction } from "@/types/drop-down-actions";
import { AuthorizedUser } from "@/types/users";
import { Getter } from "vuex-class";

@Component({
  name: 'RegulatorAccountMenu',
  components: {
    NavButtons,
    UserMenuButton
  },
})
export default class RegulatorAccountMenu extends Vue {

  @Getter("userInfo", { namespace: namespaces.AuthModule })
  public userInfo!: AuthorizedUser;

  @Getter("dropDownActions", { namespace: namespaces.NavigationModule })
  public dropDownActions!: DropDownAction[][];
}
