import { Module } from 'vuex';
import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { RootState } from '../../types';
import { AuthState } from './types';
// TODO: remove this section when cache is turned  back on.
const user = window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user') as string) : null;
const userInfo = window.localStorage.getItem('userInfo') ? JSON.parse(window.localStorage.getItem('userInfo') as string) : null;

const state: AuthState = {
  isAuthenticated: Boolean(user) || false,
  userRoles: userInfo?.ROLES || [],
  user: user || null,
  userInfo: userInfo || null,
  userRouteNames: { DASHBOARD: { name: 'DASHBOARD' } }
};

export const AuthModule: Module<AuthState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
