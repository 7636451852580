import { storeOwnerRouteNames } from '@/route-names/store-owner';
import { logoutAction } from '@/actions/auth/actions';
import { DropDownAction } from '@/types/drop-down-actions';
import { advertiserRouteNames } from '@/route-names/advertiser';
import { regulatorRouteNames } from '@/route-names/regulator';

export const advertiserDropdownActions: DropDownAction[][] = [
  // [
  //   {
  //     name: 'my_account',
  //     icon: '/assets/icons/user.svg',
  //     path: '/advertiser/account'
  //   }
  // ],
  [
    {
      name: 'my_campaigns',
      icon: '/assets/icons/campaign.svg',
      route: advertiserRouteNames.MY_CAMPAIGNS,
    },
    // {
    //   name: 'offers',
    //   icon: '/assets/icons/offers.svg',
    //   path: '/advertiser/offers'
    // }
  ],
  [
    // {
    //   name: 'change_lang_to_arabic',
    //   icon: '/assets/icons/globe.svg',
    //   action: () => {
    //     /** */
    //   }
    // }
  ],
  [
    {
      name: 'sign_out',
      icon: '/assets/icons/signout.svg',
      action: logoutAction,
      attribute: { 'data-sign-out':'' },
      colorOverride: '#EF392E'
    }
  ],
]

export const storeOwnerDropdownActions: DropDownAction[][] = [
  [
    {
      name: 'incoming_campaigns',
      icon: '/assets/icons/campaign.svg',
      route: storeOwnerRouteNames.INCOMING_CAMPAIGNS,
    },
  ],
  // [
  //   {
  //     name: 'change_lang_to_arabic',
  //     icon: '/assets/icons/globe.svg',
  //     action: () => {
  //       /** */
  //     }
  //   }
  // ],
  // [
  //   {
  //     name: 'change_lang_to_arabic',
  //     icon: '/assets/icons/globe.svg',
  //     action: () => {}
  //   }
  // ],
  [
    {
      name: 'sign_out',
      icon: '/assets/icons/signout.svg',
      action: logoutAction,
      colorOverride: '#EF392E'
    }
  ],
]

export const regulatorDropdownActions: DropDownAction[][] = [
  [
    {
      name: 'my_campaigns',
      icon: '/assets/icons/campaign.svg',
      route: regulatorRouteNames.MY_CAMPAIGNS,
    },
  ],
  [
    {
      name: 'sign_out',
      icon: '/assets/icons/signout.svg',
      action: logoutAction,
      colorOverride: '#EF392E'
    }
  ],
]

export const sharedDropDownActions: DropDownAction[][] = [
  [
    {
      name: 'sign_out',
      icon: '/assets/icons/signout.svg',
      action: logoutAction,
      colorOverride: '#EF392E'
    }
  ],
]
