import { CampaignState } from './types';
import { RootState } from '@/store/types';
import { ActionTree, ActionContext } from 'vuex';
import { downloadEstimate, downloadInvoice, updateCampaign } from '@/actions/campaigns/actions';
import { Campaign, PendingPaymentCampaign, PublishedCampaign } from '@/types/campaigns';
import { OnlyRequired } from '@/types/misc';
import { Base64 } from '@/types/media';

export const actions: ActionTree<CampaignState, RootState> = {
  updateMyCampaign: async ({ commit, dispatch }: ActionContext<CampaignState, RootState>, campaignData: OnlyRequired<Campaign, 'ID'>) => {
    return updateCampaign(campaignData);
  },
  downloadCampaignInvoice: async ({ commit, dispatch }: ActionContext<CampaignState, RootState>, campaignData: PublishedCampaign): Promise<Base64> => {
    return downloadInvoice(campaignData.INVOICE_NUMBER);
  },
  downloadCampaignEstimate: async ({ commit, dispatch }: ActionContext<CampaignState, RootState>, campaignData: PendingPaymentCampaign): Promise<Base64> => {
    return downloadEstimate(campaignData.ESTIMATE_NUMBER);
  },
  resetState: async ({ commit }: ActionContext<CampaignState, RootState>) => {
    commit('resetState')
  },
};
