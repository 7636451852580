
import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "BasicInput",
})
export default class BasicInput extends Vue {
  @Prop({ type: String }) value!: string;
  @Prop({ type: String }) label!: string;
  @Prop({ type: String }) appendIcon!: string;

  @Emit("input")
  public update(event: any) {
    return event.target.value;
  }
}
