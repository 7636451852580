import { getCurrentUser } from '@/firebase/firebase-user';
import { FirebaseAppFirestore } from '@/firebase/firebase-app';
import firebaseNames from '@/statics/firebase-names';
import { Campaign, StoreOwnerCampaign, StoreOwnerIncomingCampaign } from '@/types/campaigns';
import { getAdSpacesByRegulatorId } from '@/actions/adspaces/actions';
import { chunk, flow, map, flatten, flatMap } from 'lodash';
import { SystemStatus } from '@/types/misc';
import { FILTER_QUERY_LIMIT } from '@/statics/main';
import { ScreenCluster } from '@/types/screen-cluster';
import { AdSpace } from '@/types/adspaces';
import firebase from 'firebase';
import DocumentSnapshot = firebase.firestore.DocumentSnapshot;
import { QuerySnapshot } from '@firebase/firestore-types';
import { uniqueArray } from '@/utils/misc';
import { getUsersInfoByIDs } from '@/actions/users/actions';
import { AuthorizedUser } from '@/types/users';

/**
 * Load all advertiser's campaigns assigned to regulator.
 */
export const loadAdvertiserCampaignsForRegulator = async (campaignStatus: SystemStatus['VAL']): Promise<Campaign[]> => {
  const currentUser = await getCurrentUser();
  const regulatorAdSpaces = await getAdSpacesByRegulatorId(currentUser!.uid);
  const screenClusterRefs = flatMap(regulatorAdSpaces, (adSpace) => adSpace?.SCREEN_CLUSTER_REF) as AdSpace['SCREEN_CLUSTER_REF'][] || []
  return flow(
    (screenClustersRefs) => map(screenClustersRefs, (screenClusterRef) => screenClusterRef?.id),
    (screenClusterIds) => chunk(screenClusterIds, FILTER_QUERY_LIMIT),
    (chunkedScreenClustersIds) => {
      return map(chunkedScreenClustersIds, (filterChunk) =>
        FirebaseAppFirestore
          .collectionGroup(firebaseNames.CAMPAIGNS.USER_CAMPAIGNS)
          .where('STATUS.VAL', '==', campaignStatus)
          .where('SCREEN_CLUSTERS_IDS', 'array-contains-any', filterChunk)
          .get())
    },
    async (snapshots) => {
      const allData = await Promise.all(snapshots)
      return flatten(allData.map((snapshot) => snapshot.docs.map((doc) => doc.data() as Campaign)))

    }
  )(screenClusterRefs);
};


export const loadRegulatorStoreOwnerCampaigns = async (status: SystemStatus['VAL']): Promise<StoreOwnerIncomingCampaign[]> => {
  const currentUser = await getCurrentUser();
  const regulatorAdSpaces = await getAdSpacesByRegulatorId(currentUser!.uid);
  const screenClusterRefs = flatMap(regulatorAdSpaces, (adSpace) => adSpace?.SCREEN_CLUSTER_REF) as AdSpace['SCREEN_CLUSTER_REF'][] || []
  const adSpacesIds = flatMap(regulatorAdSpaces, (adSpace) => adSpace?.ID);
  const adSpacesIdsMap = adSpacesIds.reduce((adSpacesIdsRecord, adSpaceId) => ({
    ...adSpacesIdsRecord,
    [adSpaceId]: adSpaceId
  }), {} as Record<string, string>);

  const screenClusterIdsList = screenClusterRefs.reduce((screenClusterRefsRecord, screenClusterRef) => ({
    ...screenClusterRefsRecord,
    [screenClusterRef!.id]: screenClusterRef!.id
  }), {} as Record<string, string>)

  return flow(
    (screenClustersRefs) => map(screenClustersRefs, (screenClusterRef) => screenClusterRef?.id) as ScreenCluster['ID'][],
    (screenClusterIds) => chunk(screenClusterIds, FILTER_QUERY_LIMIT) as Array<ScreenCluster['ID'][]>,
    (chunkedScreenClustersIds) => {
      return map(chunkedScreenClustersIds, (filterChunk) =>
        FirebaseAppFirestore
          .collectionGroup(firebaseNames.STORE_OWNER_CAMPAIGNS.VAL)
          .where('STATUS.VAL', '==', status)
          .where('SCREEN_CLUSTERS_IDS', 'array-contains-any', filterChunk)
          .get()) as Array<Promise<QuerySnapshot<StoreOwnerIncomingCampaign>>>
    },
    async (snapshots): Promise<StoreOwnerIncomingCampaign[]> => {
      const allData = await Promise.all(snapshots)
      return flatten(allData.map((snapshot) => snapshot.docs.map((doc) => doc.data() as StoreOwnerIncomingCampaign)))
        .map((campaign) => {
          campaign.isStoreOwner = true;

          campaign.SCREEN_CLUSTERS_IDS = campaign.SCREEN_CLUSTERS_IDS.filter(screenClusterId => screenClusterIdsList[screenClusterId])
          campaign.MEDIA_LIST = campaign.MEDIA_LIST?.filter(mediaFile => adSpacesIdsMap[mediaFile!.AD_SPACE_ID as string])
          return campaign
        });

    }
  )(screenClusterRefs)
};


export const getRegulatorScreenClusters = async (): Promise<ScreenCluster[]> => {
  const currentUser = await getCurrentUser();
  const regulatorAdSpaces = await getAdSpacesByRegulatorId(currentUser!.uid);
  const screenClusterRefs = flatMap(regulatorAdSpaces, (adSpace) => adSpace?.SCREEN_CLUSTER_REF) as AdSpace['SCREEN_CLUSTER_REF'][] || []

  if (!screenClusterRefs.length) {
    return [];
  }

  const storeScreenClustersSnaps = screenClusterRefs
    .filter(Boolean)
    .map((documentRef) => documentRef?.get());

  return (await Promise.all(storeScreenClustersSnaps)).map((snapshot) =>
    snapshot?.data()
  ) as ScreenCluster[];
};

export const fetchStoreOwnersUsersIdsMapFromStoreOwnerCampaigns = async (storeOwnerCampaigns: StoreOwnerCampaign[]): Promise<Record<AuthorizedUser['UID'], AuthorizedUser>> => {
  const uniqueStoreOwnerUIDs = uniqueArray(storeOwnerCampaigns as StoreOwnerCampaign[], (storeOwnerCampaign) => storeOwnerCampaign.STORE_OWNER_UID)
  if (uniqueStoreOwnerUIDs.length === 0) {
    return {}
  }
  const storeOwners = await getUsersInfoByIDs(uniqueStoreOwnerUIDs);
  return storeOwners.reduce((storeOwnersRecord, storeOwner) =>
    ({ ...storeOwnersRecord, [storeOwner.UID]: storeOwner }), {})
}
