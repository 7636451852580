import { Discount, PromoCodeData } from '@/types/promotions';
import { Campaign } from '@/types/campaigns';
import { MediaData, MediaFile } from '@/types/media';
import { TimeSlotSelection } from '@/types/time-slots';
import { PaymentData, PaymentMetaData } from '@/types/payment';
import { MutationTree } from 'vuex';
import { CampaignWizardState } from './types';
import { LocationData, LocationPrice } from '@/types/locations';
import Vue from 'vue';
import { defaultCampaignFrequency } from '@/statics/campaign-wizard';
import { mapMediaResolutionsToCampaignSelectedLocations } from '@/utils/media';

export const mutations: MutationTree<CampaignWizardState> = {
  selectLocation: (state: CampaignWizardState, location: LocationData) => {
    // TODO: transform the data structure into a Record
    state.selectedLocations
      ? state.selectedLocations.push(location)
      : (state.selectedLocations = [location]);
  },
  selectLocations: (state: CampaignWizardState, locations: LocationData[]) => {
    state.selectedLocations = locations;
  },
  syncSelectedLocations: (
    state: CampaignWizardState,
    incomingLocations: LocationData[]
  ) => {
    const { selectedLocations = [] } = state;
    if (!selectedLocations.length) {
      return;
    }
    const syncedLocations = selectedLocations.filter(
      ({ ID: selectedLocationId }) =>
        incomingLocations.some(
          ({ ID: incomingLocationId }) =>
            incomingLocationId === selectedLocationId
        )
    );
    state.selectedLocations = syncedLocations;
  },
  syncRequiredMediaResolutions: (state: CampaignWizardState) => {
    const { selectedLocations = [], media } = state;

    if (!selectedLocations.length) {
      return;
    }

    const updatedMediaFiles = mapMediaResolutionsToCampaignSelectedLocations(selectedLocations,media?.files||[])

    Vue.set(state, 'media', {
      ...state.media,
      files: updatedMediaFiles,
    });
  },
  deselectLocation: (state: CampaignWizardState, location: LocationData) => {
    const { selectedLocations = [] } = state;
    const { ID: incomingLocationId } = location;
    const locationIdx = selectedLocations.findIndex(
      ({ ID: locationId }) => locationId === incomingLocationId
    );
    const updatedLocations = [...selectedLocations];

    if (locationIdx >= 0) {
      updatedLocations?.splice(locationIdx, 1);
    }

    state.selectedLocations = updatedLocations;
  },
  deselectAllLocations: (state: CampaignWizardState) => {
    state.selectedLocations = [];
  },
  updateName: (state: CampaignWizardState, name: string) => {
    state.name = name;
  },
  updateAdvertiserName: (state: CampaignWizardState, advertiserName: string) => {
    state.advertiserName = advertiserName;
  },
  updateLocationPrices: (state: CampaignWizardState, locationPrices: Record<LocationData['ID'],LocationPrice>) => {
    state.locationPrices = locationPrices;
  },
  resetName: (state: CampaignWizardState) => {
    state.name = '';
  },
  updateDesignerEmail: (state: CampaignWizardState, designerEmail: string) => {
    state.designerEmail = designerEmail;
  },
  resetDesignerEmail: (state: CampaignWizardState) => {
    state.designerEmail = '';
  },
  updateLocations: (
    state: CampaignWizardState,
    locationData: LocationData[]
  ) => {
    state.selectedLocations = locationData;
  },
  updateTime: (
    state: CampaignWizardState,
    timeSlotSelection: TimeSlotSelection
  ) => {
    state.time = { ...state.time, ...timeSlotSelection };
  },
  resetTime: (state: CampaignWizardState) => {
    state.time = {
      frequency: defaultCampaignFrequency,
    };
  },
  updateMedia: (state: CampaignWizardState, mediaData: MediaData) => {
    Vue.set(state, 'media', { ...state.media, ...mediaData });
  },
  resetMedia: (state: CampaignWizardState) => {
    state.media = {};
  },
  updatePayment: (state: CampaignWizardState, paymentData: PaymentData) => {
    state.payment = { ...state.payment, ...paymentData };
  },
  resetPayment: (state: CampaignWizardState) => {
    state.payment = {} as PaymentData;
  },
  updateStep: (state: CampaignWizardState, step: number) => {
    state.step = step;
  },
  setActivity: (state: CampaignWizardState, active: boolean) => {
    state.active = active;
  },
  setSavedCampaign: (state: CampaignWizardState, campaign: Campaign) => {
    state.savedCampaign = campaign;
  },
  setPromoCode: (state: CampaignWizardState, promoCode: PromoCodeData) => {
    state.promoCode = promoCode;
  },
  setDiscounts: (
    state: CampaignWizardState,
    discounts: Discount[] | undefined
  ) => {
    Vue.set(state.payment as PaymentData, 'discounts', discounts);
  },
  setPaymentMetaData: (
    state: CampaignWizardState,
    metaData: PaymentMetaData
  ) => {
    Vue.set(state.payment as PaymentData, 'metaData', metaData);
  },
  setTotalPaymentPrice: (state: CampaignWizardState, price: number) => {
    state.payment!.totalPrice = price;
  },
  setTotalDiscount: (state: CampaignWizardState, totalDiscount: number) => {
    state.payment!.totalDiscount = totalDiscount;
  },
};
