import { PaymentBill, PaymentData, PaymentStatus } from './payment';
import { CampaignTimeSlot, TimeSlotSelection } from './time-slots';
import { LocationData, LocationPrice } from '@/types/locations';
import { CampaignMedia, MediaData, MediaFile } from '@/types/media';
import { Nullable, SystemStatus, Tuple, WithRequired } from './misc';
import { Discount } from './promotions';
import { ClusterId, ScreenCluster, ScreenClusterWithZohoItem } from './screen-cluster';
import firebase from 'firebase';
import firestore = firebase.firestore;
import { AdSpacePriceControl } from '@/types/adspaces';
import { AuthorizedUser } from '@/types/users';

export interface CampaignSummary {
  name: string;
  locations: LocationData[];
  time: TimeSlotSelection,

  media: MediaData;
  payment?: PaymentData;
}

export type CampaignClusterPrice = number;

export type CampaignClustersPricesRecord = Record<ClusterId, CampaignClusterPrice>;
export type CampaignClustersDiscountsRecord =  Record<ClusterId, Nullable<Discount>>;

export interface CampaignPriceInfo extends PaymentBill {
  CLUSTERS_PRICES_RECORD?: CampaignClustersPricesRecord;
  SELECTED_CLUSTERS_SUBTOTAL_PRICES_RECORD: CampaignClustersPricesRecord;
  SELECTED_CLUSTERS_TOTAL_PRICES_RECORD: CampaignClustersPricesRecord;
  CLUSTERS_DISCOUNTS_RECORD: CampaignClustersDiscountsRecord;
}

export interface CampaignRequest {
  ID: string;
  ADVERTISER_UID: string;
}

export interface CampaignCheckoutRequest {
  campaignRequest: CampaignRequest;
  promoCode?: string;
  language?: 'en' | 'ar';
}

export enum FREQUENCY {
  // '1/4X' = '1/4x',
  '1/2X' = '1/2x',
  '1X'   = '1x',
  '2X'   = '2x',
}

export interface CampaignFrequency {
  NAME: FREQUENCY;
  MINUTES: number;
  COST_FACTOR: number;
  LABEL: string;
}

export interface Campaign {
  ID: string;
  NAME: string;
  START_DATE: firestore.Timestamp;
  DURATION_IN_WEEKS: number;
  SCREEN_CLUSTERS_IDS: string[];
  END_DATE: firestore.Timestamp;
  MEDIA_FILE?: string;
  DESIGNER_EMAIL?: string;
  MEDIA_TYPE?: 'image/jpeg' | 'image/gif' | 'video/mp4';
  MEDIA_PLAYBACK_DURATION?: number;
  FREQUENCY: CampaignFrequency;
  MEDIA_UPLOADED_AT?: firestore.Timestamp;
  MEDIA_SKIPPED?: boolean;
  MEDIA_LIST?: CampaignMedia[];
  STATUS: SystemStatus;
  ADVERTISER_UID: string;
  COMMENTS?: string;
  PAYMENT_BILL?: CampaignPriceInfo;
  RESERVED_UNTIL?: firestore.Timestamp;
  RESERVED_TIME_SLOTS?: CampaignTimeSlot[];
  PAYMENT_REFERENCE?: string;
  PAYMENT_STATUS?: PaymentStatus;
  SUBSCRIPTION?: boolean;
  SKIP_INVOICE?: boolean;
  INVOICE_NUMBER?: string;
  ESTIMATE_NUMBER?: string;
  MEDIA_CHANGE_ACTION_STORE_OWNER_ID?: string;
  MEDIA_CHANGE_REQUEST_STATUS?: SystemStatus;
  // CREATED_BY_STORE_OWNER?: boolean;
  // CREATED_BY_ADMIN?: boolean;
  APPROVED_BY_ADMIN?: boolean;
  APPROVED_BY_ADMIN_AT?: firestore.Timestamp | string;
  APPROVED_BY_STORE_OWNER?: boolean;
  ACTION_STORE_OWNER_ID?: string;
  DESIGNER_ACCESS_CODE?: string;
  SEND_UPLOAD_LINK_TO_DESIGNER?: boolean;
  SEND_QUOTATION_EMAIL?: boolean;

  /** @deprecated  now we use screenClusterId*/
  SCREEN_CLUSTERS?: ScreenCluster[],
  /** @deprecated  since the migration to the new platform 3.0*/
  CREATED_BY_STORE_OWNER?: boolean;
  CREATED_BY_ADMIN?: boolean;
  MEDIA_CHANGE_REQUESTED_AT?: firestore.Timestamp;
  REJECTED_AT?: firestore.Timestamp;

  /** @deprecated  because of the remove of SCHEDULES entity from our domain*/
  SELECTED_CLUSTERS_SCHEDULES?: ClustersSchedulesRecord,
  PRICE_CONTROL: AdSpacePriceControl,
}
/** @deprecated because of the remove of SCHEDULES entity from our domain */
export interface Schedule {
  ID: string;
  AD_SPACE_ID: string;
  START_DATE: string;
  END_DATE: string;
  START_MONTH: string;
  END_MONTH: string;
  WEEK: number;
  DAYS: string;
  START_YEAR: string;
  END_YEAR: string;
  SCHEDULE_NAME: string;
  FREE_SLOTS_COUNT?: number;
  TIME_SLOT_ID?: string;
  AVAILABLE: boolean;
  TOTAL_BILL_PRICE: number;
  BASE_PRICE: number;
  PRICE_INCREASE_PERCENTAGE: number;
  PRICE_INCREASE_REASON: string;
  SELECTED?: boolean;
}
export type AdSpaceSchedules = Schedule[];
export type ClustersSchedulesRecord = Record<ClusterId, AdSpaceSchedules>;
export type CampaignRef = firestore.DocumentReference<firestore.DocumentData>;

export type CampaignTuple = Tuple<Campaign, CampaignRef>;

export interface CampaignListItem extends Campaign {
  REF: firestore.DocumentReference;
  SCREEN_CLUSTERS_NAMES: string;
  COMPANY_NAME?: string;
  PRICE?: string;
  [key: string]: any;
}

export type CampaignTableRow = CampaignListItem;

export interface CampaignMetaData {
  icon: string;
  type: string;
}

export interface PendingPaymentCampaign extends
  WithRequired<Campaign, 'PAYMENT_BILL' | 'RESERVED_TIME_SLOTS' | 'RESERVED_UNTIL' | 'ESTIMATE_NUMBER'> {
  SCREEN_CLUSTERS: ScreenClusterWithZohoItem[];
}
export interface PublishedCampaign extends
  WithRequired<Campaign, 'PAYMENT_BILL' | 'RESERVED_TIME_SLOTS' | 'PAYMENT_STATUS' | 'PAYMENT_REFERENCE' | 'INVOICE_NUMBER'> {
  SCREEN_CLUSTERS: ScreenClusterWithZohoItem[];
}
export type ApprovedCampaign  = WithRequired<PublishedCampaign, 'APPROVED_BY_ADMIN' | 'APPROVED_BY_STORE_OWNER' | 'APPROVED_BY_ADMIN_AT'>;

export interface CategorizedCampaignsListItem {
  name: string;
  campaigns: Campaign[];
  defaultMessage: string;
}

export interface CategorizedMyCampaignsListItem {
  name: string;
  campaigns: AdvertiserCampaign[];
  defaultMessage: string;
}

export interface CategorizedStorePendingCampaigns {
  name: string;
  message: string;
  hint: string;
  description: string;
  campaigns: PendingCampaign[];
  defaultMessage: string;
}

export interface AdvertiserCampaign {
  name: string;
  designerEmail?: string;
  startDate: Date;
  endDate: Date;
  locations: LocationData[];
  status: SystemStatus;
  durationInWeeks: number;
  mediaList: MediaFile[];
  campaignRequest: CampaignRequest;
  rejectionReason?: string;
  rejectedAt?: Date;
  rejectedBy?: string;
  dbDataObject: Campaign;
  [key: string]: any;
}

export interface StoreCampaign {
  name: string;
  startDate: Date;
  endDate: Date;
  locations: LocationData[];
  branchesNames: string;
  status: SystemStatus;
  category: string;
  durationInWeeks: string;
  remainingDuration: string;
  advertiserCompanyName: string;
  mediaList: MediaFile[];
  changeRequestMedia?: MediaFile;
  approvedAt: Date | string;
  mediaChangeRequestedAt: Date | string;
  campaignRequest: CampaignRequest,
  earnedRevenue?: number | string,
  expectedTotalEarning: number,

  [key: string]: any;
}

export type PendingCampaign = StoreCampaign;
export type UpcomingCampaign = StoreCampaign;
export type RunningCampaign = StoreCampaign;
export type CompletedCampaign = StoreCampaign;

export type CategorizedMyCampaignsList = CategorizedMyCampaignsListItem[];
export type CategorizedCampaignsList = CategorizedCampaignsListItem[];
export type CategorizedStorePendingCampaignsList = CategorizedStorePendingCampaigns[];

export interface CompactCampaign {
  name: string;
  startDate: string;
  endDate: string;
  status: SystemStatus;
  advertiser: string;
  duration: string;
}

export interface CampaignAccessTokenRequest{
  CAMPAIGN_ID:string,
  ADVERTISER_UID:string,
  UID:string,
}

export interface GenerateSignInLinkRequest{
  campaignId:string,
  userId:string,
  designerAccessCode:string,
}

export interface ChangeCampaignApproval {
  APPROVED_BY_ADMIN?: Campaign['APPROVED_BY_ADMIN'],
  APPROVED_BY_STORE_OWNER?: Campaign['APPROVED_BY_STORE_OWNER'],
  STATUS?: Campaign['STATUS'],
  MEDIA_CHANGE_REQUEST_STATUS?: Campaign['MEDIA_CHANGE_REQUEST_STATUS'],
  COMMENTS?: Campaign['COMMENTS'],
  APPROVED_BY_ADMIN_AT?: Campaign['APPROVED_BY_ADMIN_AT'] | null;
  MEDIA_CHANGE_REQUESTED_AT?: Campaign['MEDIA_CHANGE_REQUESTED_AT'] | null;
}

export interface StoreOwnerIncomingCampaign extends Campaign {
  storePaymentBill?: PaymentBill,
  storeRevenue?: {
    storeSubtotalPricesRecord?: CampaignClustersPricesRecord,
    storeTotalPricesRecord?: CampaignClustersPricesRecord,
    storePricesRecord?: CampaignClustersPricesRecord,
    storeDiscountsRecord?: CampaignClustersDiscountsRecord,
  };
  storeScreenClustersIds?: string[],
  storeClustersSchedules?: ClustersSchedulesRecord,
  storeClustersIds?: string[];
  storeClusters?: ScreenCluster[],
  LOCATION_PRICES?: Record<LocationData['ID'],LocationPrice>;
  isStoreOwner?: boolean;
  ADVERTISER_NAME?: string;
}

export interface ShashaAd {
  ID: string;
  MEDIA_FILE: CampaignMedia;
  NAME: string;
  ADMIN_UID: AuthorizedUser['UID'];
  FREQUENCY: CampaignFrequency;
  AD_SPACE_ID: string;
  START_DATE: firestore.Timestamp;
  END_DATE: firestore.Timestamp;
  mediaFile: MediaFile;
  advertiserName: 'Shasha';
  isShashaAd?: true;
}

export interface CampaignWithSingleAdSpaceMedia extends Campaign{
  mediaFile?: MediaFile;
  companyName?: string;
  advertiserName?: string;
}

export type PlaylistAdvertiserCampaign = CampaignWithSingleAdSpaceMedia;

export type PlaylistCampaign =
  PlaylistAdvertiserCampaign | ShashaAd | StoreOwnerCampaign;

export interface AdSpacePlaylistRequest {
  adSpaceId: string;
  selectedDate: string;
  sortByFrequency: boolean;
  isUserView?: boolean;
}

export interface StoreOwnerCampaign {
  ID?: string;
  MEDIA_LIST?: CampaignMedia[];
  ADVERTISER_NAME: string;
  FREQUENCY: CampaignFrequency;
  START_DATE: firestore.Timestamp;
  END_DATE: firestore.Timestamp;
  STORE_OWNER_UID: AuthorizedUser['UID'];
  DURATION_IN_WEEKS: number;
  SCREEN_CLUSTERS_IDS: string[];
  mediaFile?: string;
  isStoreOwner: boolean;
  PRICE_CONTROL: AdSpacePriceControl;
  advertiserName?: string;
  LOCATION_PRICES?: Record<LocationData['ID'],LocationPrice>;
}

export interface CreateStoreOwnerCampaign {
  ID?: string;
  MEDIA_LIST?: CampaignMedia[];
  ADVERTISER_NAME: string;
  STORE_OWNER_UID: AuthorizedUser['UID'];
  FREQUENCY: CampaignFrequency;
  START_DATE: firestore.Timestamp;
  END_DATE?: firestore.Timestamp;
  DURATION_IN_WEEKS?: number;
  SCREEN_CLUSTERS_IDS: string[];
  mediaFile?: string;
  isStoreOwner: boolean;
  PRICE_CONTROL: AdSpacePriceControl;
  advertiserName?: string;
  LOCATION_PRICES?: Record<LocationData['ID'], LocationPrice>;
}

export interface SerializedFirestoreTimestamp {
  _seconds: number,
}
