import { getCurrentUser } from '@/firebase/firebase-user';
import { FirebaseAppFirestore } from '@/firebase/firebase-app';
import firebaseNames from '@/statics/firebase-names';
import { Campaign, StoreOwnerIncomingCampaign } from '@/types/campaigns';
import { getAdSpaceByRegulatorId } from '@/actions/adspaces/actions';
import { chunk, flow, map, flatten } from 'lodash';
import { SystemStatus } from '@/types/misc';
import { FILTER_QUERY_LIMIT } from '@/statics/main';
import { ScreenCluster } from '@/types/screen-cluster';
import { AdSpace } from '@/types/adspaces';
import { getUserInfoById } from '@/actions/users/actions';

/**
 * Load all advertiser's campaigns assigned to regulator.
 */
export const loadAdvertiserCampaignsForRegulator = async (campaignStatus: SystemStatus['VAL']): Promise<Campaign[]> => {
  const currentUser = await getCurrentUser();
  const screenClusterRefs = [(await getAdSpaceByRegulatorId(currentUser!.uid))?.SCREEN_CLUSTER_REF] as AdSpace['SCREEN_CLUSTER_REF'][] || []

  return flow(
    (screenClustersRefs) => map(screenClustersRefs, (screenClusterRef) => screenClusterRef?.id),
    (screenClusterIds) => chunk(screenClusterIds, FILTER_QUERY_LIMIT),
    (chunkedScreenClustersIds) => {
      return map(chunkedScreenClustersIds, (filterChunk) =>
        FirebaseAppFirestore
          .collectionGroup(firebaseNames.CAMPAIGNS.USER_CAMPAIGNS)
          .where('STATUS.VAL', '==', campaignStatus)
          .where('SCREEN_CLUSTERS_IDS', 'array-contains-any', filterChunk)
          .get())
    },
    async (snapshots) => {
      const allData = await Promise.all(snapshots)
      return flatten(allData.map((snapshot) => snapshot.docs.map((doc) => doc.data() as Campaign)))

    }
  )(screenClusterRefs);
};


export const loadRegulatorStoreOwnerCampaigns = async (status:SystemStatus['VAL']): Promise<Campaign[]> => {
  const currentUser = await getCurrentUser();
  const screenClusterRefs = [(await getAdSpaceByRegulatorId(currentUser!.uid))?.SCREEN_CLUSTER_REF] as AdSpace['SCREEN_CLUSTER_REF'][] || []

  return flow(
    (screenClustersRefs) => map(screenClustersRefs, (screenClusterRef) => screenClusterRef?.id),
    (screenClusterIds) => chunk(screenClusterIds, FILTER_QUERY_LIMIT),
    (chunkedScreenClustersIds) => {
      return map(chunkedScreenClustersIds, (filterChunk) =>
        FirebaseAppFirestore
          .collectionGroup(firebaseNames.STORE_OWNER_CAMPAIGNS.VAL)
          .where('STATUS', '==', status)
          .where('SCREEN_CLUSTERS_IDS', 'array-contains-any', filterChunk)
          .get())
    },
    async (snapshots) => {
      const allData = await Promise.all(snapshots)
      return flatten(allData.map((snapshot) => snapshot.docs.map((doc) => doc.data() as StoreOwnerIncomingCampaign))).map((campaign)=>{
        campaign.isStoreOwner = true;
        return campaign
      });

    }
  )(screenClusterRefs)
};


export const getRegulatorScreenClusters = async (): Promise<ScreenCluster[]> => {
  const currentUser = await getCurrentUser();
  const screenClusterRefs = [(await getAdSpaceByRegulatorId(currentUser!.uid))?.SCREEN_CLUSTER_REF] as AdSpace['SCREEN_CLUSTER_REF'][] || []

  if (!screenClusterRefs.length) {
    return [];
  }

  const storeScreenClustersSnaps = screenClusterRefs
    .filter(Boolean)
    .map((documentRef) => documentRef?.get());

  return (await Promise.all(storeScreenClustersSnaps)).map((snapshot) =>
    snapshot?.data()
  ) as ScreenCluster[];
};

export const getStoreOwnerUserFromRegulator = async () => {
  const currentUser = await getCurrentUser();
  const adSpace = await getAdSpaceByRegulatorId(currentUser!.uid)
  return getUserInfoById(adSpace?.SPACE_OWNER_ID || '')
}
