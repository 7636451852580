import { getUserRouteNames } from '@/utils/user';
import { GetterTree } from 'vuex';
import { AuthState } from './types';
import { RootState } from '../../types';
import { getUserRoleValue } from '@/utils/user';
import { Role, UserRouteNames } from '@/types/users';

export const getters: GetterTree<AuthState, RootState> = {
  user: (state: AuthState) => {
    return state.user;
  },
  userInfo: (state: AuthState) => {
    return state.userInfo;
  },
  isAuthenticated: (state: AuthState) => {
    return state.isAuthenticated;
  },
  userRoles: (state: AuthState): Role[] => {
    return state.userRoles;
  },
  userRouteNames: (state: AuthState): UserRouteNames => {
    const { userRoles: roles } = state;
    const userRole = getUserRoleValue(roles);
    return getUserRouteNames(userRole) || state.userRouteNames;
  },
};
