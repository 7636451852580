import { UserRouteNames } from '@/types/users';
import { storeOwnerRouteNames } from '@/route-names/store-owner';
import { advertiserRouteNames } from '@/route-names/advertiser';
import { regulatorRouteNames } from '@/route-names/regulator';

export const userRouteNamesMap: {
  [key: string]: UserRouteNames;
} = {
  advertiser: advertiserRouteNames,
  'store-owner': storeOwnerRouteNames,
  regulator: regulatorRouteNames,
};
