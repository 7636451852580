
import { NavButtonData } from "@/types/nav-buttons";
import { Component, Prop, Vue } from "vue-property-decorator";
import NavButton from "../NavButton/NavButton.vue";

@Component({
  components: { NavButton },
  name: "NavButtons",
})
export default class extends Vue {
  @Prop({ required: true, type: Array }) buttons!: NavButtonData[];
}
