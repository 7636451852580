import moment from 'moment';
import { GetterTree } from 'vuex';
import { RootState } from '../../types';
import { FinancialState } from './types';

export const getters: GetterTree<FinancialState, RootState> = {
  plannedCampaignsCategories: (state: FinancialState) => {
    return [
      {
        name: 'completed_campaigns',
        color: '#42CE69',
        count: 35
      },
      {
        name: 'pending_campaigns',
        color: '#EFD02E',
        count: 15
      },
      {
        name: 'running_campaigns',
        color: '#CE177C',
        count: 45
      }
    ];
  },
  totalPlannedCampaigns: (state: FinancialState) => {
    return 90;
  },
  thisQuarterStartDate: (state: FinancialState) => {
    return state.quarterStartDate;
  },
  thisQuarterEndDate: (state: FinancialState) => {
    return state.quarterEndDate;
  },
  selectedQuarterEarningStartDate: (state: FinancialState) => {
    return state.selectedQuarterEarningStartDate;
  },
  selectedQuarterEarningEndDate: (state: FinancialState) => {
    return state.selectedQuarterEarningEndDate;
  },
  statisticsStatus: (state: FinancialState) => {
    return state.statisticsStatus;
  },
  currentEarnings: (state: FinancialState) => {
    return state.currentEarnings;
  },
  expectedEarnings: (state: FinancialState) => {
    return state.expectedEarnings;
  },
  quarterEarningsChartData: (state: FinancialState) => {
    return state.quarterEarningsChartData;
  },
  runningCampaigns: (state: FinancialState) => {
    return state.runningCampaigns;
  },
  transactionsHistory: (state: FinancialState) => {
    return state.transactionsHistory;
  },
  earningsHistoryCampaignsCount: (state: FinancialState) => {
    return state.earningsHistoryCampaignsCount;
  },
  earningsHistoryLocationsCount: (state: FinancialState) => {
    return state.earningsHistoryLocationsCount;
  },
  earningsHistorySelectedLocation: (state: FinancialState) => {
    return state.earningsHistorySelectedLocation;
  },
  earningsHistoryStartDate: (state: FinancialState) => {
    return state.earningsHistoryStartDate;
  },
  earningsHistoryEndDate: (state: FinancialState) => {
    return state.earningsHistoryEndDate;
  },
  totalEarningsHistory: (state: FinancialState) => {
    return state.totalEarningsHistory;
  },
};
