import {
  CampaignRequest,
  PendingCampaign,
  StoreCampaign,
  UpcomingCampaign,
} from '@/types/campaigns';
import { IncomingCampaignsState } from './types';
import { MutationTree } from 'vuex';
import { CompletedCampaign, RunningCampaign } from '@/types/campaigns';
import Vue from 'vue';
import { SYSTEM_STATUS } from '@/statics/system-status';

export const mutations: MutationTree<IncomingCampaignsState> = {
  resetAllCampaigns: (state: IncomingCampaignsState) => {
    state.pendingCampaigns = [];
    state.completedCampaigns = [];
    state.upcomingCampaigns = [];
    state.runningCampaigns = [];
    state.activeTab = '';
    state.selectedPage = 1;
  },
  setPendingCampaigns: (
    state: IncomingCampaignsState,
    campaigns: PendingCampaign[]
  ) => {
    state.pendingCampaigns = campaigns;
  },
  setUpcomingCampaigns: (
    state: IncomingCampaignsState,
    campaigns: UpcomingCampaign[]
  ) => {
    state.upcomingCampaigns = campaigns;
  },
  setCompletedCampaigns: (
    state: IncomingCampaignsState,
    campaigns: CompletedCampaign[]
  ) => {
    state.completedCampaigns = campaigns;
  },
  setRunningCampaigns: (
    state: IncomingCampaignsState,
    campaigns: RunningCampaign[]
  ) => {
    state.runningCampaigns = campaigns;
  },
  omitPendingCampaign: (
    state: IncomingCampaignsState,
    campaignRequest: CampaignRequest
  ) => {
    const { ID: campaignId } = campaignRequest;
    const filteredCampaigns = state.pendingCampaigns.filter(
      ({ campaignRequest: { ID } }) => ID !== campaignId
    );

    Vue.set(state, 'pendingCampaigns', filteredCampaigns);
  },
  pushToRunningCampaigns: (
    state: IncomingCampaignsState,
    campaign: StoreCampaign
  ) => {
    const { runningCampaigns } = state;
    if (runningCampaigns && runningCampaigns.length) {
      const updatedRunningCampaigns = [
        ...runningCampaigns,
        {
          ...campaign,
          status: SYSTEM_STATUS.UPCOMING,
        },
      ];
      Vue.set(state, 'runningCampaigns', updatedRunningCampaigns);
    }
  },
  updateRunningCampaign: (
    state: IncomingCampaignsState,
    campaign: StoreCampaign
  ) => {
    const { runningCampaigns } = state;

    if (runningCampaigns && runningCampaigns.length) {
      const runningCampaignIndex = runningCampaigns.findIndex(
        ({ campaignRequest: { ID } }) => campaign.campaignRequest.ID === ID
      );

      if (runningCampaignIndex > 0) {
        const runningCampaign = runningCampaigns[runningCampaignIndex];
        const updatedCampaign = {
          ...runningCampaign,
          media: runningCampaign.changeRequestMedia,
        };
        Vue.set(state.runningCampaigns, runningCampaignIndex, updatedCampaign);
      }
    }
  },
  updateActiveTab(state: IncomingCampaignsState,activeTab:string){
    Vue.set(state, 'activeTab', activeTab);
  },
  updateSelectedPage(state: IncomingCampaignsState,selectedPage:number){
    Vue.set(state, 'selectedPage', selectedPage);
  },
};
