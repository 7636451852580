
import namespaces from "@/store/namespaces";
import { Getter, Action } from "vuex-class";
import { Component, Prop, Vue } from "vue-property-decorator";
import { HideSnackbar, SnackbarData } from "@/types/snackbar";

@Component({
  name: "SnackBar",
})
export default class SnackBar extends Vue {
  @Getter("snackbar", { namespace: namespaces.UiModule })
  public snackbar!: SnackbarData;

  @Action("hideSnackbar", { namespace: namespaces.UiModule })
  public hideSnackbar!: HideSnackbar;
}
