import { Module } from 'vuex';
import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { RootState } from '../../types';
import { AdvertiserState } from './types';

const state: AdvertiserState = {
  myCampaigns: [],
  runningCampaigns: [],
  pendingCampaigns: [],
  closedCampaigns: [],
  draftCampaigns: [],
  wallet: {}
};

export const AdvertiserModule: Module<AdvertiserState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
