import { Module } from 'vuex';
import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { RootState } from '../../types';
import { CampaignState } from './types';

export const getDefaultState = (): CampaignState => {
  return {
    currentStoreCampaignRef: null,
    selectedClustersSchedules: {},
    selectedClusterId: '',
    selectedSchedulesInCluster: [],
  };
};

const state: CampaignState = getDefaultState();

export const CampaignModule: Module<CampaignState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

