export const defaultAppName = 'shasha';
export const shashaHomeUrl = process.env.VUE_APP_HOME_URL || 'https://shasha.io';
export const termsAndConditionsLink = 'https://shasha.io/en/terms-and-conditions';
export const privacyPolicyLink = 'https://shasha.io/en/privacy-policy';
export const returnPolicyLink = 'https://shasha.io/en/return-policy';
export const PROJECTS = {
  LOCALHOST: 'localhost',
  DEVELOPMENT: 'development-bb',
  STAGING: 'shasha-staging',
  QA: 'shasha-quality-assurance',
  PRODUCTION: 'shasha-production',
};
export const FILTER_QUERY_LIMIT = 10
