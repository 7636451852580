import { PAYMENT_STATUSES } from '@/statics/payment';
import { Campaign, CampaignPriceInfo } from './campaigns';
import { Discount, Promotion } from './promotions';
import { Nullable } from '@/types/misc';

export type PaymentOption = Nullable<'CREDIT_CARD' | 'SADAD' | 'PAY_LATER'>;

export interface CreditCardPaymentInfo {
  brand?: string;
  cardNumber?: string;
  expiryDate?: string;
  holderName?: string;
  cvv?: string;
}

export interface SadadPaymentInfo {
  info: any;
}

export type PayLaterPaymentInfo = RecentBillingContact;

export type PaymentInfo =
  | CreditCardPaymentInfo
  | SadadPaymentInfo
  | PayLaterPaymentInfo;
export interface PaymentData {
  option?: PaymentOption;
  info?: PaymentInfo;
  totalPrice?: PaymentBill['TOTAL_PRICE'];
  totalDiscount?: PaymentBill['TOTAL_DISCOUNT'];
  discounts?: Discount[],
  metaData?: PaymentMetaData,
}

export interface CampaignCheckoutData {
  campaignId?: Campaign['ID'];
  promoCode: Promotion['CODE'];
}

export interface PaymentOptionData {
  name: string;
  value: string;
  icon: string | string[];
  disabled?: boolean;
  comingSoon?: boolean;
}

export class RecentBillingContact {
  public email: string = '';
  public phone: string = '';
}

export interface CreditCardBrand {
  name: string;
  value: string;
  icon: string;
}


export interface PaymentMetaData {
  checkoutId: string;
  paymentBill: CampaignPriceInfo;
  returnUrl: string;
}

export interface PaymentBill {
  SUBTOTAL: number;
  DISCOUNTS_LIST: Discount[];
  ERROR_MESSAGE?: string;
  TOTAL_DISCOUNT: number;
  VAT_PERCENTAGE: number;
  TOTAL_PRICE: number;
}

export type PaymentStatus = keyof typeof PAYMENT_STATUSES;
