import { RootState } from '@/store/types';
import { ActionTree, ActionContext } from 'vuex';
import { NavigationState } from './types';

export const actions: ActionTree<NavigationState, RootState> = {
  initializeSideNavItems: async (context: ActionContext<NavigationState, RootState>) => {
    // const features = await getSystemFeaturesAction();
    // context.commit('setSystemFeatures', features);
  },

  initializeDropDownItems: async (context: ActionContext<NavigationState, RootState>) => {
    // const features = await getSystemFeaturesAction();
    // context.commit('setSystemFeatures', features);
  },
};
