import { Country } from '@/types/company-profile';
import { Media, MediaType } from '@/types/media';
import { City } from '@/types/locations';
import { StoreContractType } from '@/types/store';
import firebase from 'firebase';
import firestore = firebase.firestore;

export interface AdSpaceType {
  DISPLAY_NAME: string;
  VAL: string;
}

export enum AdSpaceScreensPosition {
  INDOOR = 'indoor',
  OUTDOOR = 'outdoor',
}

export enum AdSpacePriceControl {
  BY_SHASHA = 'shasha',
  BY_SPACE_OWNER = 'space_owner',
}

export interface AdSpace {
  ID: string;
  SCREEN_CLUSTER_REF?: firestore.DocumentReference;
  SPACE_OWNER_ID: string;
  CONTRACT_TYPE: StoreContractType;
  NAME: string;
  BRANCH: string;
  BRAND_NAME: string;
  DESCRIPTION: string;
  TYPE: AdSpaceType;
  SCREENS_POSITION: AdSpaceScreensPosition;
  CITY: City;
  // Region is superset of cities
  REGION: City;
  COUNTRY: Country;
  BASE_PRICE: number;
  PRICE_INCREASE_PERCENTAGE?: number;
  PRICE_INCREASE_REASON?: string;
  SCREENS_COUNT: number;
  SCREENS_SIZES: string;
  PRICE_CONTROL: AdSpacePriceControl;
  SCREENS_RESOLUTION: {
    WIDTH: number;
    HEIGHT: number;
    UNIT: 'px';
  };
  MEDIA?: Media[];
  MEDIA_FILE?: string;
  MEDIA_TYPE?: MediaType;
  ADDRESS: string;
  VISITORS_PER_WEEK: number;
  COORDINATES: {
    lng: number;
    lat: number;
  };
  PRIORITY?: number; /** Priority of appearance on the locations cards list, '1' being the highest */
  REGULATOR_ID?: string
}

export type AdSpaceTypesIcons = Record<string, { icon: string, pinIcon: string }>
