import { Module } from 'vuex';
import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { RootState } from '../../types';
import { CampaignWizardState } from './types';
import { defaultCampaignFrequency } from '@/statics/campaign-wizard';

const state: CampaignWizardState = {
  active: false,
  step: 1,
  selectedLocations: [],
  name: '',
  advertiserName: '',
  locationPrices: {},
  designerEmail: '',
  time: {
    frequency: defaultCampaignFrequency
  },
  payment: {},
  media: {},
  promoCode: {},
  savedCampaign: null,
};

export const CampaignWizardModule: Module<CampaignWizardState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
