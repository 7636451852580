import { Route, RawLocation } from 'vue-router';
import { publicRouteNames } from '@/route-names/public';
import store from '@/store';
import { nextTo } from '@/utils/router';

/**
 * Used for Route level authentication.
 */
export const authorizeGuard = async (
  to: Route,
  _: Route,
  next: (to?: RawLocation) => void
) => {
  const continueTo = nextTo(next);
  const continueToLogin = continueTo({
    ...publicRouteNames.LOGIN,
  });

  try {
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
    const isAuthenticated = store.getters['AuthModule/isAuthenticated'];

    if (requiresAuth && !isAuthenticated) {
      return continueToLogin();
    }

    next();
  } catch ({ message }) {
    continueToLogin();
    throw new Error(`On Auth Guard, ${message}`);
  }
};
