import { MutationTree } from 'vuex';
import { NavigationState } from './types';

export const mutations: MutationTree<NavigationState> = {
  initSideNavItems: (state: NavigationState, payload: any) => {
    /** */
  },

  initDropDownActions: (state: NavigationState, payload: any) => {
    /** */
  },
};
