import Vue from 'vue';
import { MutationTree } from 'vuex';
import { AdvertiserState, MyCampaignsCategories } from './types';

export const mutations: MutationTree<AdvertiserState> = {
  setMyCampaigns: (state: AdvertiserState, campaignsCategories: MyCampaignsCategories) => {
    Vue.set(state, 'myCampaigns', campaignsCategories.myCampaigns);
    Vue.set(state, 'draftCampaigns', campaignsCategories.draftCampaigns);
    Vue.set(state, 'closedCampaigns', campaignsCategories.closedCampaigns);
    Vue.set(state, 'pendingCampaigns', campaignsCategories.pendingCampaigns);
    Vue.set(state, 'runningCampaigns', campaignsCategories.runningCampaigns);
  },
  resetAllCampaigns: (state: AdvertiserState) => {
    state.myCampaigns = [];
    state.runningCampaigns = [];
    state.pendingCampaigns = [];
    state.closedCampaigns = [];
    state.draftCampaigns = [];
    state.wallet = {};
  },
};
