
import { Component, Prop, Vue } from 'vue-property-decorator';
import Logo from '@/ui-components/Logo/Logo.vue';
import MenuToggle from '@/ui-components/MenuToggle/MenuToggle.vue';

@Component({
  components: {
    Logo,
    MenuToggle,
  },
})
export default class AppBarStart extends Vue {
  @Prop({ type: Boolean }) isAuthenticated!: boolean;
  @Prop() dashboardRoute!: Location;
}
