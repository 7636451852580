import { publicRoutes } from './public-routes';
import { adminRoutes } from './admin-routes';
import { advertiserRoutes } from './advertisers-routes';
import { storeOwnerRoutes } from './store-owner-routes';
import { regulatorRoutes } from './regulator-routes';

export const routes = [
  ...adminRoutes,
  ...advertiserRoutes,
  ...storeOwnerRoutes,
  ...regulatorRoutes,
  ...publicRoutes,
];
