import { Module } from 'vuex';
import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { RootState } from '../../types';
import { FinancialState } from './types';
import moment from 'moment';
import { transactionsHistory } from '@/statics/financial';

const state: FinancialState = {
  runningCampaigns: [],
  quarterEndDate: new Date().toISOString(),
  quarterStartDate: new Date().toISOString(),
  quarterEarningsChartData: {},
  selectedQuarterEarningStartDate: moment().toISOString(),
  selectedQuarterEarningEndDate: moment().add(3, 'years').toISOString(),
  currentEarnings: 40000,
  expectedEarnings: 40000,
  statisticsStatus: 50,
  totalEarningsHistory: 350000,
  earningsHistoryLocationsCount: 15,
  earningsHistoryCampaignsCount: 15,
  transactionsHistory,
};

export const FinancialModule: Module<FinancialState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
