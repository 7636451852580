export const errors = {
  EMAIL_EXIST_ERROR: 'auth/email-already-in-use',
  EMAIL_NOT_FOUND_ERROR: 'auth/user-not-found',
  INVALID_OOB_CODE: 'INVALID_OOB_CODE',
  WRONG_PASSWORD: 'auth/wrong-password',
  MOBILE_NUMBER_ALREADY_REGISTERED: 'MOBILE_NUMBER_ALREADY_REGISTERED',
  COMPANY_NAME_ALREADY_REGISTERED: 'COMPANY_NAME_ALREADY_REGISTERED',
  VAT_NUMBER_ALREADY_REGISTERED: 'VAT_NUMBER_ALREADY_REGISTERED',
  NOT_SUPPORTED_FILE_TYPE: 'NOT_SUPPORTED_FILE_TYPE',
  MAX_FILE_SIZE_EXCEEDED: 'MAX_FILE_SIZE_EXCEEDED',
  MEDIA_RESOLUTION_NOT_MATCH: 'MEDIA_RESOLUTION_NOT_MATCH',
  VIDEO_DURATION_EXCEEDED: 'VIDEO_DURATION_EXCEEDED',
};
