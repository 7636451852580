import { FREQUENCY, CampaignFrequency } from './../types/campaigns';
import { Step } from '@/types/campaign-wizard';

export const steps: Step[] = [
  {
    name: 'location_s',
  },
  {
    name: 'time',
  },
  {
    name: 'ad_content',
  },
  {
    name: 'account',
    onlyPublic: true,
  },
  {
    name: 'payment',
  },
];

export const storeOwnerSteps: Step[] = [
  {
    name: 'location_s',
  },
  {
    name: 'time',
  },
  {
    name: 'ad_content',
  },
];

export const FREQUENCY_SLIDER_VALUES: Record<FREQUENCY, number> = {
  [FREQUENCY['1/2X']]: 0,
  [FREQUENCY['1X']]: 1,
  [FREQUENCY['2X']]: 2,
}

const COST_SCALING_FACTOR = 0.7;

/** Campaign's ad frequency of appearance in a playlist*/
export const campaignFrequencies: Record<FREQUENCY, CampaignFrequency> = {
  [FREQUENCY['1/2X']]: {
    NAME: FREQUENCY['1/2X'],
    COST_FACTOR: COST_SCALING_FACTOR,
    MINUTES: 4,
    LABEL: 'saver',
  },
  [FREQUENCY['1X']]: {
    NAME: FREQUENCY['1X'],
    COST_FACTOR: 1,
    MINUTES: 2,
    LABEL: 'super',
  },
  [FREQUENCY['2X']]: {
    NAME: FREQUENCY['2X'],
    COST_FACTOR: 1 / COST_SCALING_FACTOR,
    MINUTES: 1,
    LABEL: 'premium',
  },
};

export const campaignSliderFrequencies: Record<number, CampaignFrequency> = {
  [FREQUENCY_SLIDER_VALUES[FREQUENCY['1/2X']]]: campaignFrequencies[FREQUENCY['1/2X']],
  [FREQUENCY_SLIDER_VALUES[FREQUENCY['1X']]]: campaignFrequencies[FREQUENCY['1X']],
  [FREQUENCY_SLIDER_VALUES[FREQUENCY['2X']]]: campaignFrequencies[FREQUENCY['2X']],
}

export const defaultCampaignFrequency = campaignFrequencies[FREQUENCY['2X']];
