import { RootState } from '@/store/types';
import { SnackbarData } from '@/types/snackbar';
import { ActionTree, ActionContext } from 'vuex';
import { UiState } from '../ui-module/types';

export const actions: ActionTree<UiState, RootState> = {
  toggleSideMenu: async (context: ActionContext<UiState, RootState>) => {
    context.commit('toggleSideMenu');
  },

  toggleSideMenuCollapse: async (context: ActionContext<UiState, RootState>) => {
    context.commit('toggleSideMenuCollapse');
  },

  showSnackbar: async ({ commit }: ActionContext<UiState, RootState>, data: SnackbarData) => {
    commit('showSnackbar', data);
  },

  hideSnackbar: async ({ commit }: ActionContext<UiState, RootState>) => {
    commit('hideSnackbar');
  },
};
