
import namespaces from "@/store/namespaces";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";

@Component({
  name: "MenuCollapseToggle",
})
export default class MenuCollapseToggle extends Vue {
  @Prop({ type: String, default: "dark" }) color!: string;
  @Prop({ type: String, default: "/assets/icons/left-chevron.svg" })
  icon!: string;
  @Prop({ type: Boolean, default: true }) small!: boolean;

  @Action("toggleSideMenuCollapse", { namespace: namespaces.UiModule })
  public toggleSideMenuCollapse() {}

  @Getter("isSideMenuCollapsed", { namespace: namespaces.UiModule })
  public isCollapsed!: boolean;
}
