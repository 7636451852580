const AdminLayout = () => import('@/layouts/AdminLayout/AdminLayout.vue');


export const adminRoutes = [
  {
    path: '/admin',
    component: AdminLayout,
    meta: {
      requiresAuth: true,
    },
    children: []
  },
];