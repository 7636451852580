
import { Component, Vue } from "vue-property-decorator";
import SnackBar from "./ui-components/SnackBar/SnackBar.vue";
import { Action } from 'vuex-class';
import namespaces from '@/store/namespaces';
import { ResetCampaign } from '@/store/modules/campaign-wizard/types';

@Component({
  components: {
    SnackBar
  }
})
export default class App extends Vue {
  @Action('resetCampaign', { namespace: namespaces.CampaignWizardModule })
  public resetCampaign!: ResetCampaign;

  created() {
    const isPageOpened = window.sessionStorage.getItem('pageOpened')
    if (isPageOpened) {
      const cacheTime = window.localStorage.getItem('cacheTime');
      if (!cacheTime) {
        window.localStorage.setItem('cacheTime', Date.now().toString());
      } else {
        const date = new Date(+cacheTime).getDate();
        const currentDate = new Date().getDate()

        if (date !== currentDate) {
          window.localStorage.removeItem('cacheTime')
          this.resetCampaign()
          window.location.reload();
        }
      }
    } else {
     this.resetCache();
    }

    window.sessionStorage.setItem('pageOpened', '1')
  }

  resetCache(){
    window.localStorage.removeItem('cacheTime')
    this.resetCampaign()
  }
}
