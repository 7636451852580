import { MutationTree } from 'vuex';
import { AuthState } from './types';
import { Role } from '@/types/users';
import firebase from 'firebase';
import { AuthorizedUser } from '@/types/users';
import Vue from 'vue';

export const mutations: MutationTree<AuthState> = {
  setIsAuthenticated: (state: AuthState, isAuthenticated: boolean) => {
    Vue.set(state, 'isAuthenticated', isAuthenticated);
  },
  setUserInfo: (state: AuthState, userInfo: AuthorizedUser) => {
    Vue.set(state, 'userInfo', userInfo);
  },
  setUser: (state: AuthState, user: firebase.User) => {
    Vue.set(state, 'user', user);
  },
  setUserRoles: (state: AuthState, userRoles: Role[]) => {
    Vue.set(state, 'userRoles', userRoles);
  },
  logout: (state: AuthState) => {
    Vue.set(state, 'user', undefined);
    Vue.set(state, 'userInfo', undefined);
  },
};
