import { CommonState, LoadCampaign, LoadLocations, LoadMyCampaigns } from './types';
import { RootState } from '@/store/types';
import { ActionContext, ActionTree } from 'vuex';
import {
  getActiveClusteredAdSpaces,
  getAllClusteredAdSpaces, loadStoreOwnerLocationsAction,
} from '@/actions/adspaces/actions';
import { typeFromMime } from '@/utils/files';
import { LocationData } from '@/types/locations';
import { loadCampaignById, updateCampaign, } from '@/actions/campaigns/actions';
import { Campaign } from '@/types/campaigns';
import { OnlyRequired } from '@/types/misc';
import { loadMyCampaigns } from '@/actions/campaigns/advertiser';
import { AdSpace, AdSpacePriceControl } from '@/types/adspaces';
import { adSpaceTypesIcons } from '@/statics/ad-spaces';
import { checkShowOnlyActiveStoresFlag } from '@/utils/locations';

export const actions: ActionTree<CommonState, RootState> = {
  loadLocations: async ({
                          commit,
                          dispatch,
                        }: ActionContext<CommonState, RootState>
  ): Promise<ReturnType<LoadLocations>> => {
    const showActiveStoresOnly = await checkShowOnlyActiveStoresFlag();
    const adSpaces: AdSpace[] = (showActiveStoresOnly
      ? await getActiveClusteredAdSpaces()
      : await getAllClusteredAdSpaces());

    const locations = locationDataFactory(adSpaces);

      commit('setLocations', locations);
    await dispatch('CampaignWizardModule/syncSelectedLocations',
      locations, {
      root: true,
    });
    return locations;
  },
  loadCampaign: async (
    { commit, dispatch }: ActionContext<CommonState, RootState>,
    campaignId: string
  ): Promise<ReturnType<LoadCampaign>> => {
    return loadCampaignById(campaignId);
  },
  loadMyCampaigns: async ({
                            commit,
                          }: ActionContext<CommonState, RootState>
  ): Promise<ReturnType<LoadMyCampaigns>> => {
    const campaigns = await loadMyCampaigns();
    commit('setMyCampaigns', campaigns);
    return campaigns;
  },
  updateCampaign: async (
    { commit, dispatch }: ActionContext<CommonState, RootState>,
    campaignData: OnlyRequired<Campaign, 'ID'>
  ) => {
    return updateCampaign(campaignData);
  },
  loadStoreOwnerLocations: async ({ commit, rootGetters }: ActionContext<CommonState, RootState>) => {
    const userInfo = rootGetters['AuthModule/userInfo']

    const locations = await loadStoreOwnerLocationsAction()

    const storeOwnerLocations = locations.filter((location: LocationData) => location.SPACE_OWNER_ID === userInfo?.UID)

    commit('setLocations', storeOwnerLocations)

    return storeOwnerLocations;
  },
  loadRegulatorLocations: async ({ commit, dispatch, rootGetters }: ActionContext<CommonState, RootState>) => {
    const userInfo = rootGetters['AuthModule/userInfo']

    const locations = await dispatch('loadLocations')

    const regulatorLocations = locations.filter((location: LocationData) => location?.REGULATOR_ID === userInfo?.UID)

    commit('setLocations', regulatorLocations)

    return regulatorLocations;
  },
};

export function locationDataFactory(adSpaces: AdSpace[]): LocationData[]{
return adSpaces.map(
    (adSpace: AdSpace) => ({
      ...adSpace,
      clusterId: adSpace.SCREEN_CLUSTER_REF!.id,
      mediaList: (adSpace.MEDIA || []).map(({ PATH, TYPE }) => ({
        path: PATH,
        type: typeFromMime(TYPE),
        mime: TYPE,
      })),
      media: {
        path: adSpace.MEDIA_FILE || adSpaceTypesIcons[adSpace.TYPE.VAL]?.icon,
        type: typeFromMime(adSpace.MEDIA_TYPE),
        mime: adSpace.MEDIA_TYPE,
      },
      selectable: Boolean(adSpace.BASE_PRICE),
      isPriceControlByShasha: adSpace.PRICE_CONTROL === AdSpacePriceControl.BY_SHASHA || !adSpace.PRICE_CONTROL,
    })
  );
}
