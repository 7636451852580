export const publicRouteNames = {
  HOME: { name: 'HOME' },
  LOGIN: { name: 'LOGIN' },
  FORGOT_PASSWORD: { name: 'FORGOT_PASSWORD' },
  RESET_PASSWORD: { name: 'RESET_PASSWORD' },
  REGISTER: { name: 'REGISTER' },
  CREATE_CAMPAIGN: { name: 'CREATE_CAMPAIGN' },
  CAMPAIGN_SUBMIT: { name: 'CAMPAIGN_SUBMIT' },
  ADMIN_LOGIN: { name: 'ADMIN_LOGIN' },
};
