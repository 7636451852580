
import namespaces from "@/store/namespaces";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Action } from 'vuex-class';

@Component({
  name: "MenuToggle",
})
export default class extends Vue {
  @Prop({ type: String, default: "dark" }) color!: string;
  @Prop({ type: String, default: '/assets/icons/hamburger-menu.svg' }) icon!: string;
  @Prop({ type: Boolean, default: true }) small!: boolean;

  @Action("toggleSideMenu", { namespace: namespaces.UiModule })
  public toggleSideMenu() {}
}
