import { DateRange } from '@/types/misc';
import { AuthorizedUser } from '@/types/users';
import { getCampaignEndDate, getCampaignRemainingDuration, getCampaignStartDate } from './../../../utils/campaigns';
import { CompactCampaign } from '@/types/campaigns';
import { FinancialState } from './types';
import { loadLatestStoreCampaigns } from '@/actions/campaigns/store-owner';
import { RootState } from '@/store/types';
import { ActionTree, ActionContext } from 'vuex';
import { getUsersInfoByIDs } from '@/actions/users/actions';
import moment from 'moment';

export const actions: ActionTree<FinancialState, RootState> = {
  loadRunningCampaigns: async ({ commit }: ActionContext<FinancialState, RootState>) => {
    const campaigns = await loadLatestStoreCampaigns();
    const campaignsAdvertisersUIDs = campaigns.map(({ ADVERTISER_UID }) => ADVERTISER_UID);
    const campaignsAdvertisersList = await getUsersInfoByIDs(campaignsAdvertisersUIDs);
    const runningCampaigns: CompactCampaign[] = campaigns
      .map((campaign) => {
        const { STATUS: status, NAME: name, ADVERTISER_UID: advertiserUID } = campaign;
        const startDate = moment(getCampaignStartDate(campaign)).format('DD MMM YYYY');
        const endDate = moment(getCampaignEndDate(campaign)).format('DD MMM YYYY');
        const duration = getCampaignRemainingDuration(campaign);
        const { NAME: advertiser } = campaignsAdvertisersList.find(({ UID }) => UID === advertiserUID) || {} as AuthorizedUser;
        return {
          name,
          startDate,
          endDate,
          status,
          duration,
          advertiser,
        };
      });

    commit('setRunningCampaigns', runningCampaigns);
  },

  setQuarterEarningsDateRange: async ({ commit }: ActionContext<FinancialState, RootState>, dateRange: DateRange) => {
    // TODO: add async logic
    commit('setQuarterEarningsDateRange', dateRange);
  },

  setEarningsHistoryDateRange: async ({ commit }: ActionContext<FinancialState, RootState>, dateRange: DateRange) => {
    // TODO: add async logic
    commit('setEarningsHistoryDateRange', dateRange);
  },
  setEarningsHistorySelectedLocation: async ({ commit }: ActionContext<FinancialState, RootState>, locationId: string) => {
    // TODO: add async logic
    commit('setEarningsHistorySelectedLocation', locationId);
  },
};
