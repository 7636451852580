
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { publicRouteNames } from '@/route-names/public';
import { Getter } from 'vuex-class';
import namespaces from '@/store/namespaces';

@Component({
  name: "AppBar",
})
export default class extends Vue {
  @Prop({ type: Boolean, default: true }) flat!: boolean;
  @Prop({ type: String, default: "#FFF" }) color!: string;
  @Prop({ type: Boolean, default: true }) bordered!: boolean;
  @Prop({ type: String, default: "60px" }) height!: string;
  @Prop({ type: Boolean, default: true }) narrow!: boolean;
  @Prop({ type: Boolean, default: true }) isHeaderVisible!: boolean;

  @Getter('step', { namespace: namespaces.CampaignWizardModule })
  public currentStep!: number;


  classname = false;
  key = 1;

  get isCampaignWizardPage() {
    return this.$router.currentRoute.name === publicRouteNames.CREATE_CAMPAIGN.name;
  }

  get isMobileViewPort() {
    return this.$vuetify.breakpoint.mdAndDown;
  }

  get isHeaderVisibleComputed() {
    return this.isHeaderVisible;
  }

  set isHeaderVisibleComputed(value) {
    if (this.shouldHideOnScroll) {
      this.$emit('update:isHeaderVisible', value)
    } else {
      this.$emit('update:isHeaderVisible', true)
    }
  }

  get shouldMinimizeHeaderHeight() {
    return this.shouldHideOnScroll && !this.isHeaderVisible
  }

  get shouldHideOnScroll() {
    return this.isCampaignWizardPage && this.isMobileViewPort;
  }

  @Watch('currentStep')
  remountHeaderAfterStepChange() {
    setTimeout(() => {
      this.key++;
    }, 500)
  }
}
