import { errors } from '@/statics/errors';
import { Base64, MediaResolution, MimeString } from '@/types/media';
import { SUPPORTED_TYPES } from '@/statics/supported-types';

export function downloadPdfFromBase64(
  base64String: Base64,
  saveAsName: string
) {
  const { blob } = base64ToBlob(base64String, 'application/pdf');
  return downloadBlob(blob, saveAsName);
}

/**
 * Convert Base64 (dataURI) to blob with mimeType
 * @param base64String
 * @param mimeType
 */
export const base64ToBlob = (
  base64String: Base64,
  mimeType: MimeString
): { blob: Blob; dataURI: string } => {
  const dataURI = `data:${mimeType};base64,${base64String}`;
  const binary = atob(base64String.replace(/\s/g, ''));
  // write the bytes of the string to an ArrayBuffer
  const buffer = new ArrayBuffer(binary.length);
  const view = new Uint8Array(buffer);
  for (let i = 0; i < binary.length; i++) {
    view[i] = binary.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  const blob = new Blob([buffer], { type: mimeType });
  return { blob, dataURI };
};

/**
 * requestPdf function to return a PDF from API endpoint * Converts base64 string to blob and inits download
 * @param {Blob} blob
 * @param {string} saveAsName
 * @see {@link https://code-tribe.com/automatic-pdf-download-in-all-browsers/}
 */
export function downloadBlob(blob: Blob, saveAsName: string): Blob {
  // Internet Explorer support
  // @ts-ignore
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    // @ts-ignore
    window.navigator.msSaveOrOpenBlob(blob, saveAsName);
    return blob;
  }

  const url = window.URL.createObjectURL(blob);
  downloadFromUrl(url, saveAsName);
  return blob;
}

export function downloadFromUrl(url: string, saveAsName: string): void {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', saveAsName);
  document.body.appendChild(link);
  link.click();
  link.remove();
}

export const typeFromMime = (mime: string = ''): string => {
  const [type] = (mime || '').split('/');
  return type;
};

export const nameFromPath = (path: string = ''): string => {
  const [name] = (path || '').split('/').slice(-1);
  return name;
};

export const extensionFromMime = (mime: string = ''): string => {
  return mime
    .split('/')
    .splice(-1)
    .pop() as string;
};

export const checkFileSize = ({ size }: File, maxFileSizeInMb: number) => {
  if (size > maxFileSizeInMb * 1024 * 1024) {
    throw Error(errors.MAX_FILE_SIZE_EXCEEDED);
  }
};

export const checkFileType = (
  { name }: File,
  supportedTypes: string[]
) => {
  const extension = name.split('.').pop();
  if (!supportedTypes.includes(extension!)) {
    throw Error(errors.NOT_SUPPORTED_FILE_TYPE);
  }
};

const getImageMediaResolution = (file: File): Promise<MediaResolution> => {
  return new Promise((resolve, reject) => {
    const fr = new FileReader();

    fr.onload = () => {
      const img = new Image();

      img.onload = () => {
        const { width, height } = img;
        resolve({
          width,
          height,
          unit: 'px',
        });
      };

      img.src = fr.result as string;
    };
    fr.onerror = (err) => {
      reject(err);
    };
    fr.readAsDataURL(file);
  });
};

export const getVideoMediaDuration = (file: File): Promise<number> => {
  return new Promise((resolve, reject) => {
    const blobURL = URL.createObjectURL(file);
    const video = document.createElement('video');
    video.style.opacity = '0';
    video.style.position = 'absolute';
    video.addEventListener(
      'loadedmetadata',
      function() {
        const duration = this.duration;
        video.remove();
        resolve(duration);
      },
      false
    );
    video.src = blobURL;
  });
};

export const getVideoMediaResolution = (file: File): Promise<MediaResolution> => {
  return new Promise((resolve, reject) => {
    const blobURL = URL.createObjectURL(file);
    const video = document.createElement('video');
    video.style.opacity = '0';
    video.style.position = 'absolute';
    video.addEventListener(
      'loadedmetadata',
      function() {
        const height = this.videoHeight;
        const width = this.videoWidth;
        video.remove();
        resolve({ height, width, unit: 'px' });
      },
      false
    );
    video.addEventListener('error', () => {
      video.remove();
      reject(errors.MEDIA_RESOLUTION_NOT_MATCH);
    });
    video.src = blobURL;
  });
};

export const getMediaResolution = (file: File): Promise<MediaResolution> => {
  const { type } = file;
  const isVideo = type.includes('video');

  return isVideo
    ? getVideoMediaResolution(file)
    : getImageMediaResolution(file);
};

export const checkMediaResolution = async (
  file: File,
  { width: requiredMediaWidth, height: requiredMediaHeight }: MediaResolution
) => {
  try {
    if (!requiredMediaWidth && !requiredMediaHeight) {
      return;
    }
    const { width: mediaWidth, height: mediaHeight } = await getMediaResolution(
      file
    );
    const requiredAspectRatio = requiredMediaWidth / requiredMediaHeight;
    const tolerance = requiredAspectRatio * 0.1;
    const minAspectRatio = requiredAspectRatio - tolerance;
    const maxAspectRatio = requiredAspectRatio + tolerance;
    const fileAspectRatio = mediaWidth / mediaHeight;
    if (fileAspectRatio < minAspectRatio || fileAspectRatio > maxAspectRatio) {
      throw new Error(errors.MEDIA_RESOLUTION_NOT_MATCH);
    }
  } catch (error) {
    throw new Error(errors.MEDIA_RESOLUTION_NOT_MATCH);
  }
};

export const fileNameFromUrl = (url: string = '') => {
  return url.substring(url.lastIndexOf('/') + 1);
};

export const checkVideoDuration = async (file: File)=>{
  if (file.type === SUPPORTED_TYPES.MP4) {
    const videoDuration =  await getVideoMediaDuration(file);
    if(videoDuration>10){
      throw new Error(errors.VIDEO_DURATION_EXCEEDED)
    }
  }
}
