import { AdvertiserModule } from './modules/advertiser-module/index';
import { FinancialModule } from './modules/financial-module/index';
import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { UiModule } from './modules/ui-module';
import { RootState } from './types';
import { AuthModule } from './modules/auth-module/index';
import { NavigationModule } from './modules/navigation-module/index';
import { CampaignWizardModule } from './modules/campaign-wizard/index';
import { CampaignModule } from './modules/campaign/index';
import { CommonModule } from './modules/common-module/index';
import { vuexLocal } from './plugins/vuex-local';
import { StoreModule } from './modules/store-module';
import { IncomingCampaignsModule } from './modules/incoming-campaigns-module';

Vue.use(Vuex);

const storeOpts: StoreOptions<RootState> = {
  // @ts-ignore
  state: {
    version: '1.0.0',
  },
  modules: {
    UiModule,
    AuthModule,
    NavigationModule,
    CampaignWizardModule,
    CommonModule,
    CampaignModule,
    StoreModule,
    FinancialModule,
    IncomingCampaignsModule,
    AdvertiserModule
  },
  // plugins: [vuexLocal.plugin]
};

export default new Vuex.Store(storeOpts);
