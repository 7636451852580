import { sortCampaignsByStartDate } from '@/utils/campaigns';
import { CategorizedCampaignsList } from '@/types/campaigns';
import {
  isCampaignApproved,
  isCampaignPendingApproval,
  isCampaignPendingPayment,
  isCampaignRunningOrUpcoming,
  isCampaignFinished,
  isCampaignDraft,
  isCampaignRejected,
} from '@/utils/campaigns';
import { GetterTree } from 'vuex';
import { RootState } from '../../types';
import { CommonState } from './types';

export const getters: GetterTree<CommonState, RootState> = {
  locations: (state: CommonState) => {
    return state.locations;
  },
  categorizedMyCampaignsList: ({
    myCampaigns = [],
  }: CommonState): CategorizedCampaignsList => {
    const sortedMyCampaigns = sortCampaignsByStartDate(myCampaigns);
    return [
      {
        name: 'pending_campaigns',
        campaigns: sortedMyCampaigns.filter(
          (campaign) =>
            isCampaignPendingApproval(campaign) ||
            isCampaignPendingPayment(campaign)
        ),
        defaultMessage: 'no_pending_campaigns',
      },
      {
        name: 'approved_campaigns',
        campaigns: sortedMyCampaigns.filter(isCampaignApproved),
        defaultMessage: 'no_approved_campaigns',
      },
      {
        name: 'running_campaigns',
        campaigns: sortedMyCampaigns.filter(isCampaignRunningOrUpcoming),
        defaultMessage: 'no_running_campaigns',
      },
      {
        name: 'closed_campaigns',
        campaigns: sortedMyCampaigns.filter(isCampaignFinished),
        defaultMessage: 'no_closed_campaigns',
      },
      {
        name: 'draft_campaigns',
        campaigns: sortedMyCampaigns.filter(isCampaignDraft),
        defaultMessage: 'no_draft_campaigns',
      },
      {
        name: 'requires_action_campaigns',
        campaigns: sortedMyCampaigns.filter(isCampaignRejected),
        defaultMessage: 'no_requires_action_campaigns',
      },
    ];
  },
};
