
import { Component, Vue } from "vue-property-decorator";
import BasicInput from "../../ui-components/BasicInput/BasicInput.vue";

@Component({
  name: "SearchBar",
  components: {
    BasicInput,
  },
})
export default class SearchBar extends Vue {
  public value = "";
  public label = this.$t("search_in_shasha");
  public appendIcon = "/assets/icons/magnifying-glass.svg";
}
