import { regulatorSideNavItems, storeOwnerSideNavItems } from './side-nav-items';
import { advertiserSideNavItems } from '@/statics/side-nav-items';
import { NavButtonData } from '@/types/nav-buttons';

export const userSideNavItemsMap: {
  [key: string]: NavButtonData[][];
} = {
  advertiser: advertiserSideNavItems,
  'store-owner': storeOwnerSideNavItems,
  regulator: regulatorSideNavItems,
};
