import { SnackbarData } from '@/types/snackbar';
import { MutationTree } from 'vuex';
import { UiState } from './types';

export const mutations: MutationTree<UiState> = {
  toggleSideMenu: (state: UiState) => {
    state.isSideMenuOpen = !state.isSideMenuOpen;
  },

  toggleSideMenuCollapse: (state: UiState) => {
    state.isSideMenuCollapsed = !state.isSideMenuCollapsed;
  },

  showSnackbar: (state: UiState, data: SnackbarData) => {
    data.visible = true;
    state.snackbar = data;
  },

  hideSnackbar: (state: UiState) => {
    state.snackbar.visible = false;
  },
};
