export const mockUserRoles = [
  {
    DISPLAY_NAME: 'Advertiser',
    VAL: 'advertiser',
  },
];

export const USER_ROLES = {
  ADMIN: {
    DISPLAY_NAME: 'Administrator',
    VAL: 'admin',
  },
  ADVERTISER: {
    DISPLAY_NAME: 'Advertiser',
    VAL: 'advertiser',
  },
  SCREEN_OWNER: {
    DISPLAY_NAME: 'Screen Owner',
    VAL: 'screen-owner',
  },
  STORE_OWNER: {
    DISPLAY_NAME: 'Store Owner',
    VAL: 'store-owner',
  },
  REGULATOR: {
    DISPLAY_NAME: 'Regulator',
    VAL: 'regulator',
  },
};

export const USER_TYPES_ABBREVIATIONS = {
  [USER_ROLES.ADVERTISER.VAL]: 'adv',
  [USER_ROLES.STORE_OWNER.VAL]: 'sto',
  [USER_ROLES.ADMIN.VAL]: 'adm',
}
