import { DateRange } from '@/types/misc';
import { FinancialState } from './types';
import { MutationTree } from 'vuex';
import { CompactCampaign } from '@/types/campaigns';

export const mutations: MutationTree<FinancialState> = {
  setRunningCampaigns: (state: FinancialState, campaigns: CompactCampaign[]) => {
    state.runningCampaigns = campaigns;
  },
  setQuarterEarningsDateRange: (state: FinancialState, dateRange: DateRange) => {
    state.selectedQuarterEarningStartDate = dateRange.start;
    state.selectedQuarterEarningEndDate = dateRange.end;
  },
  setEarningsHistoryDateRange: (state: FinancialState, dateRange: DateRange) => {
    state.earningsHistoryStartDate = dateRange.start;
    state.earningsHistoryEndDate = dateRange.end;
  },
  setEarningsHistorySelectedLocation: (state: FinancialState, locationId: string) => {
    state.earningsHistorySelectedLocation = locationId;
  },
};
