import { roleGuard } from './../guards/role-guard';
import VueRouter from 'vue-router';
import { routes } from '@/routes';
import multiguard from 'vue-router-multiguard';
import { authorizeGuard } from '@/guards/auth-guard';
import { publicPageGuard } from '@/guards/public-guard';
import Vue from 'vue';
import i18n from '@/plugins/i18n';
import { defaultAppName } from '@/statics/main';
import VueI18n from 'vue-i18n';

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
});

router.beforeEach(multiguard([authorizeGuard, publicPageGuard, roleGuard]));

const translate = (_i18n: VueI18n, key: string) => {
  const { locale, messages } = _i18n;
  if (messages[locale][key]) {
    return messages[locale][key];
  }
  return key;
};

router.afterEach((to) => {
  const title = to.meta?.title || '';
  if (title) {
    Vue.nextTick(() => {
      document.title = `${ translate(i18n, defaultAppName)} | ${ translate(i18n, title) }`;
    });
    return;
  }
  document.title = translate(i18n, defaultAppName) as string;
});

export default router;
