
import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "DropdownMenu",
})
export default class DropdownMenu extends Vue {
  @Prop({ type: Boolean, default: false }) rounded!: boolean;
  @Prop({ type: Boolean, default: true }) closeOnContentClick!: boolean;
  @Prop({ type: Boolean, default: true }) offsetY!: boolean;
  @Prop({ type: String }) contentClass!: string;
  @Prop({ type: Boolean }) value!: boolean;

  @Emit("input")
  public update(date: boolean) {
    return date;
  }
}
