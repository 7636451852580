import { RootState } from '@/store/types';
import { ActionTree, ActionContext } from 'vuex';
import { AuthState } from './types';
import { loginAction, logoutUserAction } from '@/actions/auth/actions';
import { LoginUserData, UserRegistrationData } from '@/types/users';
import { createAccountAction } from '@/actions/auth/actions';
import firebase from 'firebase';
import { getUserInfoById } from '@/actions/users/actions';

export const actions: ActionTree<AuthState, RootState> = {
  createAccount: async (
    { commit }: ActionContext<AuthState, RootState>,
    registrationUserData: UserRegistrationData
  ) => {
    const { user, userInfo } = await createAccountAction(registrationUserData);
    const { ROLES: userRoles } = userInfo;
    commit('setUser', user);
    commit('setUserInfo', userInfo);
    commit('setUserRoles', userRoles);
    commit('setIsAuthenticated', true);
  },
  login: async (
    { commit }: ActionContext<AuthState, RootState>,
    loginData: LoginUserData
  ) => {
    const { user, userInfo } = await loginAction(loginData);
    const { ROLES: userRoles } = userInfo;
    commit('setUser', user);
    commit('setUserInfo', userInfo);
    commit('setUserRoles', userRoles);
    commit('setIsAuthenticated', true);
  },
  adminLoginAsUser: async (
    { commit }: ActionContext<AuthState, RootState>,
    user: firebase.User
  ) => {
    const userInfo = await getUserInfoById(user!.uid)
    const { ROLES: userRoles } = userInfo;
    userInfo.isAdminSignInWithAdvertiser = true;
    commit('setUser', user);
    commit('setUserInfo', userInfo);
    commit('setUserRoles', userRoles);
    commit('setIsAuthenticated', true);
    return userInfo
  },
  logout: async ({ commit }: ActionContext<AuthState, RootState>) => {
    await logoutUserAction();
    commit('setUser', null);
    commit('setUserInfo', null);
    commit('setUserRoles', []);
    commit('setIsAuthenticated', false);
  },
};
