import Vue from 'vue';
import Vuetify from 'vuetify';
import { theme } from './theme';
import 'vuetify/dist/vuetify.min.css';
import '../styles/global.sass'

Vue.use(Vuetify);

const opts = {
  theme,
};

export default new Vuetify(opts);
