import { FirebaseAppFirestore } from '@/firebase/firebase-app';
import { getCurrentUser } from '@/firebase/firebase-user';
import firebaseNames from '@/statics/firebase-names';
import { Campaign, CampaignRequest, ChangeCampaignApproval } from '@/types/campaigns';
import { CampaignMedia } from '@/types/media';

/**
 * Load all campaigns of current user
 */
export const loadMyCampaigns = async (): Promise<Campaign[]> => {
  const currentUser = await getCurrentUser();
  const campaignsSnap = await FirebaseAppFirestore.collection(
    firebaseNames.CAMPAIGNS.VAL
  )
    .doc(currentUser!.uid)
    .collection(firebaseNames.CAMPAIGNS.USER_CAMPAIGNS)
    .get();

  if (campaignsSnap.empty) {
    return [];
  }
  const data = await Promise.all(
    campaignsSnap.docs.map((campaignDoc) => campaignDoc.data() as Campaign)
  );

  return data;
};

/**
 * Change campaign media list
 */
export const changeCampaignMediaList = async (
  campaignRequest: CampaignRequest,
  mediaList: CampaignMedia[],
  shouldResetCampaignApproval?: ChangeCampaignApproval,
): Promise<void> => {
  const { ID, ADVERTISER_UID } = campaignRequest;

  const campaignRef = await FirebaseAppFirestore.collection(
    firebaseNames.CAMPAIGNS.VAL
  )
    .doc(ADVERTISER_UID)
    .collection(firebaseNames.CAMPAIGNS.USER_CAMPAIGNS)
    .doc(ID);

  return campaignRef.update({
    MEDIA_LIST: mediaList,
    ...shouldResetCampaignApproval,
  });
};
