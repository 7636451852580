import { CampaignMedia, MediaFile } from '@/types/media';
import { FirebaseAppFirestore } from '@/firebase/firebase-app';
import { getCurrentUser } from '@/firebase/firebase-user';
import firebaseNames from '@/statics/firebase-names';
import { SYSTEM_STATUS } from '@/statics/system-status';
import {
  Campaign,
  CampaignRef,
  CampaignRequest,
  CampaignTuple,
  ShashaAd,
  StoreOwnerIncomingCampaign
} from '@/types/campaigns';
import { ScreenCluster } from '@/types/screen-cluster';
import firebase from 'firebase';
import firestore = firebase.firestore;
import { AdSpace } from '@/types/adspaces';
import { loadCampaignLocations } from '@/actions/campaigns/actions';
import { SystemStatus } from '@/types/company-profile';

/**
 * Approve campaign as store owner.
 * @param campaignRequest Campaign
 */
export const approveCampaignAction = async (
  campaignRequest: CampaignRequest
): Promise<void> => {
  const currentUser = await getCurrentUser();
  const { uid } = currentUser!;
  const { ADVERTISER_UID: userId, ID: campaignId } = campaignRequest;
  const campaignRef = FirebaseAppFirestore.collection(
    firebaseNames.CAMPAIGNS.VAL
  )
    .doc(userId)
    .collection(firebaseNames.CAMPAIGNS.USER_CAMPAIGNS)
    .doc(campaignId);
  return campaignRef.update({
    ACTION_STORE_OWNER_ID: uid,
    APPROVED_BY_STORE_OWNER: true,
    STATUS: SYSTEM_STATUS.UPCOMING
  });
};

/**
 * Approve campaign media change request as store owner.
 * @param campaignRequest
 * @param mediaList
 */
export const approveCampaignMediaChangeAction = async (
  campaignRequest: CampaignRequest,
  mediaList: MediaFile[]
): Promise<void> => {
  const {
    ADVERTISER_UID: userId,
    ID: campaignId,
  } = campaignRequest;

  const currentUser = await getCurrentUser();
  const { uid } = currentUser!;

  const campaignRef = FirebaseAppFirestore.collection(
    firebaseNames.CAMPAIGNS.VAL
  )
    .doc(userId)
    .collection(firebaseNames.CAMPAIGNS.USER_CAMPAIGNS)
    .doc(campaignId);
  const campaignDbDataObject = (await campaignRef.get()).data()
  if (!campaignDbDataObject) {
    throw new Error('Campaign not found!')
  }
  const updatedMediaList: CampaignMedia[] = campaignDbDataObject.MEDIA_LIST.map((media: CampaignMedia) => {
    return media.MEDIA_CHANGE_REQUEST ? media.MEDIA_CHANGE_REQUEST : media;
  });

  return campaignRef.update({
    MEDIA_LIST: updatedMediaList,
    MEDIA_CHANGE_ACTION_STORE_OWNER_ID: uid,
    APPROVED_BY_STORE_OWNER: true,
    MEDIA_CHANGE_REQUEST_STATUS: SYSTEM_STATUS.APPROVED,
  });
};

/**
 * Reject campaign media change request as store owner.
 * @param campaignRequest
 * @param rejectionReason
 */
export const rejectCampaignMediaChangeAction = async (
  campaignRequest: CampaignRequest,
  rejectionReason: string
): Promise<void> => {
  const {
    ADVERTISER_UID: userId,
    ID: campaignId,
  } = campaignRequest;

  const currentUser = await getCurrentUser();
  const { uid } = currentUser!;

  const campaignRef = FirebaseAppFirestore.collection(
    firebaseNames.CAMPAIGNS.VAL
  )
    .doc(userId)
    .collection(firebaseNames.CAMPAIGNS.USER_CAMPAIGNS)
    .doc(campaignId);

  return campaignRef.update({
    REJECTED_AT: firebase.firestore.Timestamp.now(),
    MEDIA_CHANGE_REQUEST_STATUS: SYSTEM_STATUS.REJECTED,
    COMMENTS: rejectionReason,
    MEDIA_CHANGE_ACTION_STORE_OWNER_ID: uid,
  });
};

/**
 * Reject campaign as store owner.
 * @param campaignRequest
 * @param rejectionReason
 */
export const rejectCampaignAction = async (
  campaignRequest: CampaignRequest,
  rejectionReason: string
): Promise<void> => {
  const currentUser = await getCurrentUser();
  const { uid } = currentUser!;
  const { ADVERTISER_UID: userId, ID: campaignId } = campaignRequest;
  const campaignRef = FirebaseAppFirestore.collection(
    firebaseNames.CAMPAIGNS.VAL
  )
    .doc(userId)
    .collection(firebaseNames.CAMPAIGNS.USER_CAMPAIGNS)
    .doc(campaignId);
  return campaignRef.update({
    ACTION_STORE_OWNER_ID: uid,
    APPROVED_BY_STORE_OWNER: false,
    REJECTED_AT: firebase.firestore.Timestamp.now(),
    STATUS: SYSTEM_STATUS.REQUIRES_ACTION,
    COMMENTS: rejectionReason,
  });
};

/**
 * Load store owner created campaigns
 */
export const loadStoreOwnerCreatedCampaignsTuples = async (): Promise<CampaignTuple[]> => {
  const currentUser = await getCurrentUser();
  const campaignsSnap = await FirebaseAppFirestore.collection(
    firebaseNames.CAMPAIGNS.VAL
  )
    .doc(currentUser!.uid)
    .collection(firebaseNames.CAMPAIGNS.USER_CAMPAIGNS)
    .get();

  if (campaignsSnap.empty) {
    return [];
  }

  return campaignsSnap.docs.map((doc) => {
    return [doc.data(), doc.ref] as CampaignTuple;
  });
};

/**
 * Load all campaigns assigned to current user's store.
 */
export const loadStoreCampaignsReferencesAction = async (): Promise<firestore.DocumentReference[]> => {
  const currentUser = await getCurrentUser();
  const campaignsSnap = await FirebaseAppFirestore.collection(
    firebaseNames.CAMPAIGNS.VAL
  )
    .doc(currentUser!.uid)
    .collection(firebaseNames.CAMPAIGNS.STORE_CAMPAIGNS)
    .get();

  if (campaignsSnap.empty) {
    return [];
  }

  const dataPromiseArray = campaignsSnap.docs.map(async (doc) => {
    const campaignRef = doc.data().campaignRef;
    return campaignRef;
  });
  return Promise.all(dataPromiseArray);
};

/**
 * Load last five campaign refs assigned to current user's store.
 */
export const loadLatestStoreCampaignsReferencesAction = async (): Promise<firestore.DocumentReference[]> => {
  const currentUser = await getCurrentUser();
  const campaignsSnap = await FirebaseAppFirestore.collection(
    firebaseNames.CAMPAIGNS.VAL
  )
    .doc(currentUser!.uid)
    .collection(firebaseNames.CAMPAIGNS.STORE_CAMPAIGNS)
    .limit(5)
    .get();

  if (campaignsSnap.empty) {
    return [];
  }

  const dataPromiseArray = campaignsSnap.docs.map(async (doc) => {
    const campaignRef = doc.data().campaignRef;
    return campaignRef;
  });

  return Promise.all(dataPromiseArray);
};


/**
 * Load store owner campaigns
 */
// export const loadStoreOwnerCampaignsAction = async (): Promise<CampaignTuple[]> => {
//   try {
//     const storeCampaigns = await loadStoreCampaignsTuples();
//     const storeCreatedCampaigns = await loadStoreOwnerCreatedCampaignsTuples();
//     return [...storeCampaigns, ...storeCreatedCampaigns];
//   } catch (err) {
//     /* tslint:disable-next-line */
//     console.error(err.stack);
//     throw err;
//   }
// };

/**
 * Load store campaigns
 */
export const loadStoreCampaigns = async (): Promise<Campaign[]> => {
  const campaignsRefs = await loadStoreCampaignsReferencesAction();
  return getCampaignsByRefs(campaignsRefs);
};
export const loadStoreOwnerCampaigns = async (screenClusterRef: ScreenCluster['ID'][],status:SystemStatus) => {
  const campaigns = await FirebaseAppFirestore.collection(
    firebaseNames.STORE_OWNER_CAMPAIGNS.VAL,
  ).where('STATUS', '==', status)
    .where('SCREEN_CLUSTERS_IDS', 'array-contains-any', screenClusterRef)
    .get();
  return campaigns.docs.map((campaignDocumentSnapshot) => campaignDocumentSnapshot.data() as StoreOwnerIncomingCampaign)
    .map((campaign: StoreOwnerIncomingCampaign) => {
      campaign.isStoreOwner = true;
      return campaign
    })

}

export const loadAdminCampaigns = async (screenClusterIds: ScreenCluster['ID'][], status:SystemStatus) => {
  const adSpaceIds = (await loadCampaignLocations(screenClusterIds)).map(({ ID }) => ID);

  const campaigns = await FirebaseAppFirestore.collection(
    firebaseNames.SHASHA_ADS.VAL,
  )
    .where('STATUS', '==', status)
    .where('AD_SPACE_ID', 'in', adSpaceIds)
    .get();

  return campaigns.docs.map((campaignDocumentSnapshot) => campaignDocumentSnapshot.data() as ShashaAd)
    .map((campaign: ShashaAd) => {
      (campaign as unknown as Campaign).MEDIA_LIST = [(campaign.MEDIA_FILE as CampaignMedia)];
      campaign.isShashaAd = true;
      return campaign as unknown as StoreOwnerIncomingCampaign
    })
}
/**
 * @description
 * Get all Screen clusters in Ad spaces of store owner.
 */
export const getStoreScreenClusters = async (): Promise<ScreenCluster[]> => {
  const currentUser = await getCurrentUser();
  const userAdSpacesSnap = await FirebaseAppFirestore.collection(
    firebaseNames.AD_SPACES
  )
    .where('SPACE_OWNER_ID', '==', currentUser!.uid)
    .get();

  if (userAdSpacesSnap.empty) {
    return [];
  }

  const storeScreenClustersSnaps = userAdSpacesSnap.docs
    .map((adSpaceDocumentSnapshot) => adSpaceDocumentSnapshot.data() as AdSpace)
    .map((adSpace) => adSpace.SCREEN_CLUSTER_REF as firebase.firestore.DocumentReference<ScreenCluster>)
    .filter(Boolean)
    .map((documentRef) => documentRef.get());

  return (await Promise.all(storeScreenClustersSnaps)).map((snapshot) =>
    snapshot.data()
  ) as ScreenCluster[];
};

const getCampaignsByRefs = async (
  refs: firestore.DocumentReference[]
): Promise<Campaign[]> => {
  const campaignsPromises = refs.map(async (ref: CampaignRef) => {
    const snap = await ref.get();
    return snap.data() as Campaign;
  });

  const campaigns = await Promise.all(campaignsPromises);
  return campaigns.filter((item) => item);
};

/**
 * @description
 * Get last five store campaigns.
 */
export const loadLatestStoreCampaigns = async (): Promise<Campaign[]> => {
  const campaignsRefs = await loadLatestStoreCampaignsReferencesAction();
  return getCampaignsByRefs(campaignsRefs);
};
