import { getUserRoleValue } from '@/utils/user';
import { GetterTree } from 'vuex';
import { NavigationState } from './types';
import { RootState } from '../../types';
import { NavButtonData } from '@/types/nav-buttons';
import { DropDownAction } from '@/types/drop-down-actions';
import { userSideNavItemsMap } from '@/statics/user-side-nav-items-map';
import { userDropDownActionsMap } from '@/statics/user-drop-down-actions-map';

export const getters: GetterTree<NavigationState, RootState> = {
  sideNavItems: (_, __, ___, rootGetters: any): NavButtonData[][] => {
    const userRoles = rootGetters['AuthModule/userRoles'];
    const userRole = getUserRoleValue(userRoles);

    return userSideNavItemsMap[userRole];
  },

  dropDownActions: (_, __, ___, rootGetters: any): DropDownAction[][] => {
    const userRoles = rootGetters['AuthModule/userRoles'];
    const userRole = getUserRoleValue(userRoles);

    return userDropDownActionsMap[userRole];
  },
};
