import { Module } from 'vuex';
import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { RootState } from '../../types';
import { IncomingCampaignsState } from './types';

const state: IncomingCampaignsState = {
  pendingCampaigns: [],
  upcomingCampaigns: [],
  runningCampaigns: [],
  completedCampaigns: [],
  screenClusters: [],
  activeTab: '',
  selectedPage: 1,
};

export const IncomingCampaignsModule: Module<IncomingCampaignsState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
