import { advertiserRouteNames } from '@/route-names/advertiser';

const AdvertiserLayout = () => import('@/layouts/AdvertiserLayout/AdvertiserLayout.vue');
const DashboardPage = () => import('@/pages/advertiser/Dashboard/Dashboard.vue');
const MyCampaignsPage = () => import('@/pages/advertiser/MyCampaigns/MyCampaigns.vue');
const ChangeMediaPage = () => import('@/pages/advertiser/ChangeMedia/ChangeMedia.vue');
const CampaignDetailsPage = () => import('@/pages/advertiser/CampaignDetails/CampaignDetails.vue');
const CompanyProfilePage = () => import('@/pages/advertiser/CompanyProfile/CompanyProfile.vue');
const HelpPage = () => import('@/pages/advertiser/Help/Help.vue');
const MediaPage = () => import('@/pages/advertiser/Media/Media.vue');
const OffersPage = () => import('@/pages/advertiser/Offers/Offers.vue');
const ShareFeedbackPage = () => import('@/pages/advertiser/ShareFeedback/ShareFeedback.vue');
const SubscriptionsPage = () => import('@/pages/advertiser/Subscriptions/Subscriptions.vue');
const WalletPage = () => import('@/pages/advertiser/Wallet/Wallet.vue');

export const advertiserRoutes = [
  {
    path: '/advertiser',
    component: AdvertiserLayout,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        ...advertiserRouteNames.DASHBOARD,
        path: 'dashboard',
        component: DashboardPage,
        children: [],
      },
      {
        ...advertiserRouteNames.MY_CAMPAIGNS,
        path: 'my-campaigns',
        component: MyCampaignsPage,
        children: [],
      },
      {
        path: 'company-profile',
        component: CompanyProfilePage,
        children: [],
      },
      {
        path: 'help',
        component: HelpPage,
        children: [],
      },
      {
        path: 'media',
        component: MediaPage,
        children: [],
      },
      {
        path: 'offers',
        component: OffersPage,
        children: [],
      },
      {
        path: 'share-feedback',
        component: ShareFeedbackPage,
        children: [],
      },
      {
        path: 'subscriptions',
        component: SubscriptionsPage,
        children: [],
      },
      {
        path: 'wallet',
        component: WalletPage,
        children: [],
      },
      {
        ...advertiserRouteNames.CAMPAIGN_DETAILS,
        path: 'my-campaigns/campaign/:id',
        component: CampaignDetailsPage,
        children: [],
      },
      {
        ...advertiserRouteNames.CHANGE_MEDIA,
        path: 'my-campaigns/change-media/:id',
        component: ChangeMediaPage,
        children: [],
      },
    ]
  },
];