import { AdSpaceTypesIcons, AdSpaceType } from '@/types/adspaces';

const STORE_TYPES_NAMES = {
  'Super Market': 'Super Market' as 'Super Market',
  'Gym (Men)':  'Gym (Men)' as 'Gym (Men)',
  'Gym (Women)': 'Gym (Women)' as 'Gym (Women)',
  'Street': 'Street' as 'Street',
  'Gas Station': 'Gas Station' as 'Gas Station',
  'Cafe': 'Cafe' as 'Cafe',
  'Cinema': 'Cinema' as 'Cinema',
  'Mall': 'Mall' as 'Mall',
}

enum STORE_TYPE {
  'Super Market'    = 'super-market',
  'Gym (Men)'       =  'gym-men',
  'Gym (Women)'     = 'gym-women',
  'Street'          = 'street',
  'Gas Station'     = 'gas-station',
  'Cafe'            = 'cafe',
  'Cinema'          = 'cinema',
  'Mall'            = 'mall',
}

export const AD_SPACES_TYPES: AdSpaceType[] = [
  {
    DISPLAY_NAME: STORE_TYPES_NAMES['Super Market'],
    VAL: STORE_TYPE['Super Market'],
  },
  {
    DISPLAY_NAME: STORE_TYPES_NAMES['Gym (Men)'],
    VAL: STORE_TYPE['Gym (Men)'],
  },
  {
    DISPLAY_NAME: STORE_TYPES_NAMES['Gym (Women)'],
    VAL: STORE_TYPE['Gym (Women)'],
  },
  {
    DISPLAY_NAME: STORE_TYPES_NAMES['Street'],
    VAL: STORE_TYPE['Street'],
  },
  {
    DISPLAY_NAME: STORE_TYPES_NAMES['Gas Station'],
    VAL: STORE_TYPE['Gas Station'],
  },
  {
    DISPLAY_NAME: STORE_TYPES_NAMES['Cafe'],
    VAL: STORE_TYPE['Cafe'],
  },
  {
    DISPLAY_NAME: STORE_TYPES_NAMES['Cinema'],
    VAL: STORE_TYPE['Cinema'],
  },
  {
    DISPLAY_NAME: STORE_TYPES_NAMES['Mall'],
    VAL: STORE_TYPE['Mall'],
  }
];

export const adSpaceTypesIcons: AdSpaceTypesIcons = {
  [STORE_TYPE['Super Market']]: {
    icon: '/assets/icons/supermarket.svg',
    pinIcon: '/assets/icons/supermarket-location-pin.svg'
  },
  [STORE_TYPE['Gym (Men)']]: {
    icon: '/assets/icons/gym-men.svg',
    pinIcon: '/assets/icons/gym-men-location-pin.svg'
  },
  [STORE_TYPE['Gym (Women)']]: {
    icon: '/assets/icons/gym-women.svg',
    pinIcon: '/assets/icons/gym-women-location-pin.svg'
  },
  [STORE_TYPE['Street']]: {
    icon: '/assets/icons/street.svg',
    pinIcon: '/assets/icons/street-location-pin.svg'
  },
  [STORE_TYPE['Gas Station']]: {
    icon: '/assets/icons/gas-station.svg',
    pinIcon: '/assets/icons/gas-station-location-pin.svg'
  },
  [STORE_TYPE['Cafe']]: {
    icon: '/assets/icons/cafe.svg',
    pinIcon: '/assets/icons/cafe-location-pin.svg'
  },
  [STORE_TYPE['Cinema']]: {
    icon: '/assets/icons/cinema.svg',
    pinIcon: '/assets/icons/cinema-location-pin.svg'
  },
  [STORE_TYPE['Mall']]: {
    icon: '/assets/icons/mall.svg',
    pinIcon: '/assets/icons/mall-location-pin.svg'
  }
};
