import { FirebaseAppFirestore } from '@/firebase/firebase-app';
import firebaseNames from '@/statics/firebase-names';
import { BillingPhoneNumber, CompanyProfile } from '@/types/company-profile';
import { Nullable } from '@/types/misc';
import { getCurrentUser } from '@/firebase/firebase-user';
import { BillingContact } from '@/types/company-profile';
import { RecentBillingContact } from '@/types/payment';
import firebase from 'firebase';

/**
 * Get company for specific User by his ID.
 * @param uid User ID
 */
export const getCompanyProfileByUserIdAction = async (
  uid: string
): Promise<Nullable<CompanyProfile>> => {
  const dataRef = await FirebaseAppFirestore.collection(
    firebaseNames.COMPANY_PROFILES
  )
    .doc(uid)
    .get();
  const dataDoc = dataRef.data();
  return dataDoc ? (dataDoc as CompanyProfile) : null;
};

/**
 * Get company profiles action.
 */
 export const loadCompanyProfilesAction = async () => {
  const dataRef = await FirebaseAppFirestore.collection(firebaseNames.COMPANY_PROFILES).get();
  const companyProfiles = dataRef.docs.map((snapshot) => {
    return {...snapshot.data(), USER_ID: snapshot.id };
  });
  return companyProfiles as CompanyProfile[];
};

/**
 * Load recent billing contact
 */
export const loadRecentBillingContactAction = async (): Promise<RecentBillingContact> => {
  const currentUser = await getCurrentUser();
  const billingContactSnap = await FirebaseAppFirestore
    .collection(firebaseNames.COMPANY_PROFILES)
    .doc(currentUser!.uid)
    .collection(firebaseNames.BILLING_CONTACTS)
    .orderBy('CREATED_AT', 'desc')
    .limit(1)
    .get();

  if (billingContactSnap.empty) {
    return new RecentBillingContact();
  }

  const [ { EMAIL: email, PHONES: [ { VALUE: phone } ] } ] = billingContactSnap.docs.map((doc) => doc.data() as BillingContact);
  return { email, phone };
};

/**
 * Load billing by email
 */
const loadBillingContactByEmail = async (email: string): Promise<BillingContact> => {
  const currentUser = await getCurrentUser();
  const billingContactSnap = await FirebaseAppFirestore
    .collection(firebaseNames.COMPANY_PROFILES)
    .doc(currentUser!.uid)
    .collection(firebaseNames.BILLING_CONTACTS)
    .where('EMAIL', '==', email)
    .get();
  const [recentBillingContact] = billingContactSnap.docs.map((doc) => doc.data() as BillingContact);
  return recentBillingContact;
};

/**
 * Create billing contact
 * @param data RecentBillingContact
 */
const createBillingContact = async ({ email: EMAIL, phone: VALUE }: RecentBillingContact): Promise<void> => {
  const currentUser = await getCurrentUser();
  const docRef = FirebaseAppFirestore
    .collection(firebaseNames.COMPANY_PROFILES)
    .doc(currentUser!.uid)
    .collection(firebaseNames.BILLING_CONTACTS)
    .doc();
  const { id: ID } = docRef;
  const CREATED_AT = firebase.firestore.Timestamp.now();
  docRef.set({
    ID,
    EMAIL,
    CREATED_AT,
    PHONES: [
      {
        VALUE,
        CREATED_AT,
      },
    ],
  });
};

const updateBillingContactPhonesList = (billingContact: BillingContact, phone: string): BillingPhoneNumber[] => {
  const isPhoneExists = billingContact && Boolean(billingContact.PHONES.find((billingPhoneNumber) => billingPhoneNumber.VALUE === phone));
  const VALUE = phone;
  const CREATED_AT = firebase.firestore.Timestamp.now();
  return isPhoneExists
    ? billingContact.PHONES
    : [
      {
        VALUE,
        CREATED_AT,
      },
      ...billingContact.PHONES,
    ];
};

/**
 * Update billing contact phone
 * @param billingContact BillingContact
 */
const updateBillingContact = async (billingContact: BillingContact): Promise<void> => {
  const currentUser = await getCurrentUser();
  const CREATED_AT = firebase.firestore.Timestamp.now();
  const { ID } = billingContact;
  const docRef = FirebaseAppFirestore
    .collection(firebaseNames.COMPANY_PROFILES)
    .doc(currentUser!.uid)
    .collection(firebaseNames.BILLING_CONTACTS)
    .doc(ID);

    await docRef.set(
      {
        ...billingContact,
        CREATED_AT,
      },
      { merge: true },
    );
};

/**
 * Update recent billing contact
 * @param data RecentBillingContact
 */
export const updateRecentBillingContact = async ({ email, phone }: RecentBillingContact): Promise<void> => {
  const billingContact = await loadBillingContactByEmail(email);
  if (!billingContact) {
    return createBillingContact({ email, phone });
  }
  const PHONES = updateBillingContactPhonesList(billingContact, phone);
  const updatedBillingContact = {
    ...billingContact,
    PHONES,
  };
  return updateBillingContact(updatedBillingContact);
};
