import { NavButtonData } from '@/types/nav-buttons';
import { advertiserRouteNames } from '@/route-names/advertiser';
import { storeOwnerRouteNames } from '@/route-names/store-owner';
import { regulatorRouteNames } from '@/route-names/regulator';

export const advertiserSideNavItems: NavButtonData[][] = [
  [
    // {
    //   name: 'dashboard',
    //   icon: '/assets/icons/dashboard.svg',
    //   route: advertiserRouteNames.DASHBOARD,
    // },
    {
      name: 'my_campaigns',
      icon: '/assets/icons/campaign.svg',
      route: advertiserRouteNames.MY_CAMPAIGNS,
    },
    {
        name: 'help_whatsapp',
        icon: '/assets/icons/whatsapp_black.svg',
        href: 'https://api.whatsapp.com/send/?phone=966509699953&text=Looking forward to help you!',
      iconClass: 'green-icon',
      },
    // {
    //   name: 'media_files',
    //   icon: '/assets/icons/image.svg',
    //   path: '/advertiser/media',
    // },
  ],
  // [
    // {
    //   name: 'wallet',
    //   icon: '/assets/icons/wallet.svg',
    //   path: '/advertiser/wallet',
    // },
    // {
    //   name: 'subscriptions',
    //   icon: '/assets/icons/subscriptions.svg',
    //   path: '/advertiser/subscriptions',
    // },
    // {
    //   name: 'offers',
    //   icon: '/assets/icons/offers.svg',
    //   path: '/advertiser/offers',
    // },
  // ],
  // [
  //   {
  //     name: 'company_profile',
  //     icon: '/assets/icons/company.svg',
  //     path: '/advertiser/company-profile',
  //   }
  // ],
  // [
  //   {
  //     name: 'help',
  //     icon: '/assets/icons/question-mark.svg',
  //     path: '/advertiser/help',
  //   },
  //   {
  //     name: 'share_feedback',
  //     icon: '/assets/icons/share-feedback.svg',
  //     path: '/advertiser/share-feedback',
  //   }
  // ],
]

export const storeOwnerSideNavItems: NavButtonData[][] = [
  // [
  //   {
  //     name: 'dashboard',
  //     icon: '/assets/icons/dashboard.svg',
  //     route: storeOwnerRouteNames.DASHBOARD,
  //   },
  //   // {
  //   //   name: 'financial',
  //   //   icon: '/assets/icons/stats.svg',
  //   //   route: storeOwnerRouteNames.FINANCIAL,
  //   // },
  //   // {
  //   //   name: 'actions_center',
  //   //   icon: '/assets/icons/actions.svg',
  //   //   route: storeOwnerRouteNames.ACTIONS_CENTER,
  //   // },
  //   // {
  //   //   name: 'manage_my_ads',
  //   //   icon: '/assets/icons/campaign.svg',
  //   //   route: storeOwnerRouteNames.MANAGE_MY_ADS,
  //   // },
  // ],
  [
    {
      name: 'incoming_campaigns',
      icon: '/assets/icons/directions.svg',
      route: storeOwnerRouteNames.INCOMING_CAMPAIGNS,
    },
    {
      name: 'playlist',
      muiIcon: 'mdi-cog',
      route: storeOwnerRouteNames.PLAYLIST,
    },
    // {
    //   name: 'branches',
    //   icon: '/assets/icons/flag.svg',
    //   route: storeOwnerRouteNames.BRANCHES,
    // },
    // {
    //   name: 'company_profile',
    //   icon: '/assets/icons/bag.svg',
    //   route: storeOwnerRouteNames.COMPANY_PROFILE,
    // }
  ],
  // [
  //   {
  //     name: 'help',
  //     icon: '/assets/icons/question-mark.svg',
  //     route: storeOwnerRouteNames.HELP,
  //   },
  //   {
  //     name: 'share_feedback',
  //     icon: '/assets/icons/share-feedback.svg',
  //     route: storeOwnerRouteNames.SHARE_FEEDBACK,
  //   }
  // ],
]

export const regulatorSideNavItems: NavButtonData[][] = [
   [
    {
      name: 'my_campaigns',
      icon: '/assets/icons/directions.svg',
      route: regulatorRouteNames.MY_CAMPAIGNS,
    },
    {
      name: 'playlist',
      muiIcon: 'mdi-cog',
      route: regulatorRouteNames.PLAYLIST,
    },
  ],
]
