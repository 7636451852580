import Vue from 'vue';
import { tuncateText, formatSaudiPhoneNumber, uppercase } from '@/utils/text';
import { formatDate, formatDateWith, formatDateTime, monthYear, year, getDateFromServerTimeStamp } from '@/utils/date';
import { currency, creditCardExpiryDate, priceByFrequency } from '@/utils/numbers';

Vue.filter('formatDate', formatDate);
Vue.filter('formatDateWith', formatDateWith);
Vue.filter('getDateFromServerTimeStamp', getDateFromServerTimeStamp);
Vue.filter('formatDateTime', formatDateTime);
Vue.filter('uppercase', uppercase);
Vue.filter('formatSaudiPhoneNumber', formatSaudiPhoneNumber);
Vue.filter('truncate', tuncateText);
Vue.filter('currency', currency);
Vue.filter('creditCardExpiryDate', creditCardExpiryDate);
Vue.filter('monthYear', monthYear);
Vue.filter('year', year);
Vue.filter('priceByFrequency', priceByFrequency)
