import { SnackbarData } from '@/types/snackbar';
import { GetterTree } from 'vuex';
import { UiState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<UiState, RootState> = {
  isSideMenuOpen: (state: UiState): boolean => {
    return state.isSideMenuOpen;
  },

  isSideMenuCollapsed: (state: UiState): boolean => {
    return state.isSideMenuCollapsed;
  },

  snackbar: (state: UiState): SnackbarData => {
    return state.snackbar;
  },
};
