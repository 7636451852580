import { StoreCampaign } from '@/types/campaigns';
import { ScreenCluster } from '@/types/screen-cluster';
import { StoreState } from './types';
import { MutationTree } from 'vuex';

export const mutations: MutationTree<StoreState> = {
  setStoreScreenClusters: (state: StoreState, screenClusters: ScreenCluster[]) => {
    state.screenClusters = screenClusters;
  },
  setStoreCampaigns: (state: StoreState, campaigns: StoreCampaign[]) => {
    state.storeCampaigns = campaigns;
  },
};
