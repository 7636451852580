import { AdvertiserCampaign } from '@/types/campaigns';
import { GetterTree } from 'vuex';
import { RootState } from '../../types';
import { AdvertiserState } from './types';

export const getters: GetterTree<AdvertiserState, RootState> = {
  runningCampaigns: ({ runningCampaigns }: AdvertiserState): AdvertiserCampaign[] =>
    runningCampaigns,
  pendingCampaigns: ({ pendingCampaigns }: AdvertiserState): AdvertiserCampaign[] =>
    pendingCampaigns,
  closedCampaigns: ({ closedCampaigns }: AdvertiserState): AdvertiserCampaign[] =>
    closedCampaigns,
  draftCampaigns: ({ draftCampaigns }: AdvertiserState): AdvertiserCampaign[] =>
    draftCampaigns,
};
