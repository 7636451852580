
import { shashaHomeUrl } from '@/statics/main';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Location } from 'vue-router';
import { publicRouteNames } from '@/route-names/public';

@Component({
  name: 'Logo',
})
export default class extends Vue {
  @Prop({ type: String, default: '/assets/logo.svg' }) logo!: string;
  @Prop({ type: String, default: '/assets/logo-white.svg' }) logoWhite!: string;
  @Prop({ type: String, default: 'auto' }) width!: string;
  @Prop({ type: String, default: '100%' }) height!: string;
  @Prop({ type: Boolean, default: false }) dark!: boolean;
  @Prop({ type: Boolean }) isAuthenticated!: boolean;
  @Prop() dashboardRoute!: Location;
  onlyLogo = '/assets/only-logo.svg';

  get isCampaignWizardPage() {
    return this.$router.currentRoute.name === publicRouteNames.CREATE_CAMPAIGN.name;
  }

  get isMobileViewPort() {
    return this.$vuetify.breakpoint.mdAndDown;
  }

  get getSrc() {
    if (this.isCampaignWizardPage && this.isMobileViewPort) {
      return this.onlyLogo
    }
    if (this.logo && this.dark) {
      return this.logoWhite
    }
    return this.logo
  }


  public clicked(_: MouseEvent) {
    if (this.isAuthenticated && this.dashboardRoute) {
      this.$router.push(this.dashboardRoute)
      return;
    }

    window.location.replace(shashaHomeUrl);
    return;
  }
}
