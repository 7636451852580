
import namespaces from '@/store/namespaces';
import { Role } from '@/types/users';
import NavigationDrawer from '@/ui-components/NavigationDrawer/NavigationDrawer.vue';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import MenuCollapseToggle from '@/ui-components/MenuCollapseToggle/MenuCollapseToggle.vue';
import Divider from '@/ui-components/Divider/Divider.vue';
import { NavButtonData } from '@/types/nav-buttons';
import { cloneDeep } from 'lodash';
import { logoutAction } from '@/actions/auth/actions';
import { ToggleSideMenu } from '@/store/modules/ui-module/types';

@Component({
  name: 'SideNav',
  components: { NavigationDrawer, MenuCollapseToggle, Divider },
})
export default class SideNav extends Vue {
  @Getter('sideNavItems', { namespace: namespaces.NavigationModule })
  public sideNavItems!: NavButtonData[][];

  @Getter('userRoles', { namespace: namespaces.AuthModule })
  public userRoles!: Role[];

  @Getter('isSideMenuOpen', { namespace: namespaces.UiModule })
  public isOpen!: boolean;

  @Getter('isSideMenuCollapsed', { namespace: namespaces.UiModule })
  public isCollapsed!: boolean;

  @Action("toggleSideMenu", { namespace: namespaces.UiModule })
  public toggleSideMenu!: ToggleSideMenu;

  @Action("toggleSideMenuCollapse", { namespace: namespaces.UiModule })
  public toggleSideMenuCollapse!: ToggleSideMenu;

  get isMobileViewPort() {
    return this.$vuetify.breakpoint.smAndDown;
  }

  get sideNavItemsComputed() {
    if (this.isMobileViewPort && this.sideNavItems) {
      const sideNavItems = cloneDeep(this.sideNavItems)
      sideNavItems.push([
        {
          name: 'sign_out',
          icon: '/assets/icons/signout.svg',
          action: logoutAction,
          colorOverride: '#EF392E'
        }])
      return sideNavItems
    }
    return this.sideNavItems
  }

  public handleClick({ action, route }: NavButtonData) {
    this.toggleSideMenu && this.toggleSideMenu();
    if (route) {
      return this.$router.push(route);
    }
    if (action) {
      return action();
    }
  }

  public hasDivider(categoryIndex: number): boolean {
    return categoryIndex < this.sideNavItemsComputed.length - 1;
  }

  public isActive(route: NavButtonData): boolean {
    const routeName = route.name.replaceAll('_', '-');
    return this.$route.path.includes(routeName);
  }

  @Watch('isMobileViewPort')
  mobileViewChanged() {
    if (this.isMobileViewPort && this.isCollapsed) {
      this.toggleSideMenuCollapse()
    }
  }

  created() {
    if (this.isMobileViewPort && this.isOpen) {
      this.toggleSideMenu();
    }
  }
}
