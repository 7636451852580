import { SystemStatuses } from '@/types/misc';

export const SYSTEM_STATUS = {
  DRAFT: {
    VAL: 'draft',
    COLOR: 'red',
  },
  NEW: {
    VAL: 'new',
    COLOR: 'blue',
  },
  CONNECTED: {
    VAL: 'connected',
    COLOR: 'lime',
  },
  UNDER_PREPARATION: {
    VAL: 'under_preparation',
    COLOR: 'orange',
  },
  PENDING_PAYMENT: {
    VAL: 'pending_payment',
    COLOR: 'lightgrey',
  },
  PENDING_APPROVAL: {
    VAL: 'pending_approval',
    COLOR: 'brown',
  },
  PENDING_CONFIRMATION: {
    VAL: 'pending_confirmation',
    COLOR: 'yellow',
  },
  PENDING_ADMIN_CONFIRMATION: {
    VAL: 'pending_admin_confirmation',
    COLOR: 'yellow darken-5',
  },
  APPROVED: {
    VAL: 'approved',
    COLOR: 'green lighten-3',
  },
  UPCOMING: {
    VAL: 'upcoming',
    COLOR: 'green lighten-3',
  },
  RUNNING: {
    VAL: 'running',
    COLOR: 'light-green darken-2',
  },
  FINISHED: {
    VAL: 'finished',
    COLOR: 'blue-grey darken-4',
  },
  REJECTED: {
    VAL: 'rejected',
    COLOR: 'red lighten-3',
  },
  CANCELED: {
    VAL: 'canceled',
    COLOR: 'red lighten-1',
  },
  REQUIRES_ACTION: {
    VAL: 'requires_action',
    COLOR: 'red lighten-3',
  },
  UPDATE_REQUEST: {
    VAL: 'update_request',
    COLOR: 'red lighten-3',
  },
};
