import i18n from '@/plugins/i18n';

export const uppercase = (text: string) => text.toUpperCase();

export const tuncateText = (text: string, stop: number, clamp: number) => {
  return text && text.slice(0, stop) + (stop < text.length ? clamp || '...' : '');
};

export const formatSaudiPhoneNumber = (text: string) => {
  return text.replace(/ /g, '').replace(/^(.{3})(.{4})(.*)$/, '$1 $2 $3');
};

export const pluralizeText = (count: number, plural: string, single: string) => {
  return count > 1 ? `${count} ${plural}` : `${count} ${single}`
}

export const pluralizeWeeks = (numberOfWeeks: number) => {
  return pluralizeText(numberOfWeeks, i18n.t('weeks').toString(), i18n.t('week').toString())
}
export const pluralizeDays = (numberOfWeeks: number) => {
  return pluralizeText(numberOfWeeks, i18n.t('days').toString(), i18n.t('day').toString())
}
