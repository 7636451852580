import { CategorizedStorePendingCampaignsList } from '@/types/campaigns';
import { GetterTree } from 'vuex';
import { RootState } from '../../types';
import { IncomingCampaignsState } from './types';
import { difference } from 'lodash';
import { SYSTEM_STATUS } from '@/statics/system-status';

export const getters: GetterTree<IncomingCampaignsState, RootState> = {
  pendingCampaigns: (state: IncomingCampaignsState): CategorizedStorePendingCampaignsList => {
    const updateRequestCampaigns = state.pendingCampaigns.filter(({ status, mediaList }) => status.VAL === SYSTEM_STATUS.RUNNING.VAL && mediaList.some(media => media.changeRequestMedia));
    const pendingApprovalCampaigns = difference(state.pendingCampaigns, updateRequestCampaigns);
    return [
      {
        name: 'pending-approval-campaigns',
        message: 'here_you_can_see_the_new_campaigns_requisites',
        description : 'advertiser_submitted_a_new_campaign',
        defaultMessage: 'it_seems_that_there_are_no_pending_campaigns_for_now',
        hint: 'you_have_24_hours_to_act_on_requests_before_it_will_be_approved_automatically',
        campaigns: pendingApprovalCampaigns,
      },
      {
        name: 'update-request-campaigns',
        message: 'here_you_can_see_the_campaigns_content_update_requisites',
        description: 'advertiser_updated_the_content_of_a_running_campaign',
        defaultMessage: 'it_seems_that_there_are_no_pending_campaigns_for_now',
        hint: 'can_be_changed_based_on_advertisers_decisions',
        campaigns: updateRequestCampaigns,
      },
    ]
  },
  completedCampaigns: (state: IncomingCampaignsState) => {
    return state.completedCampaigns;
  },
  upcomingCampaigns: (state: IncomingCampaignsState) => {
    return state.upcomingCampaigns;
  },
  runningCampaigns: (state: IncomingCampaignsState) => {
    return state.runningCampaigns;
  },
  activeTab: (state: IncomingCampaignsState) => {
    return state.activeTab;
  },
  selectedPage: (state: IncomingCampaignsState) => {
    return state.selectedPage;
  },
};
