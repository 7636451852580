import { Module } from 'vuex';
import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { RootState } from '../../types';
import { CommonState } from './types';

const state: CommonState = {
  locations: [],
  myCampaigns: [],
};

export const CommonModule: Module<CommonState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
