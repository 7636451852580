import { cloneDeep } from 'lodash/fp';
import { MutationTree } from 'vuex';
import { getDefaultState } from '.';
import { CampaignState } from './types';
import firebase from 'firebase';
import firestore = firebase.firestore;
import { ClustersSchedulesRecord } from '@/types/screen-cluster';
import { Schedule } from '@/types/schedules';

export const mutations: MutationTree<CampaignState> = {
  setCurrentStoreCampaignRef(state: CampaignState, payload: firestore.DocumentReference) {
    state.currentStoreCampaignRef = payload;
  },
  setSelectedClustersSchedules(state: CampaignState, payload: ClustersSchedulesRecord) {
    state.selectedClustersSchedules = cloneDeep(payload);
  },
  setSelectedClusterId(state: CampaignState, payload: string) {
    state.selectedClusterId = payload;
    state.selectedSchedulesInCluster = cloneDeep(state.selectedClustersSchedules[payload]) || [];
  },
  setSelectedSchedulesItems(state: CampaignState, payload: Schedule[]) {
    state.selectedSchedulesInCluster = cloneDeep(payload);
  },
  resetState(state: CampaignState) {
    Object.assign(state, getDefaultState());
  },
};
