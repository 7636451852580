export const storeOwnerRouteNames = {
  DASHBOARD: { name: 'INCOMING_CAMPAIGNS' },
  CAMPAIGNS: { name: 'STORE_OWNER_CAMPAIGNS' },
  INCOMING_CAMPAIGNS: { name: 'INCOMING_CAMPAIGNS' },
  MANAGE_MY_ADS: { name: 'MANAGE_MY_ADS' },
  CAMPAIGN_REVIEW: { name: 'CAMPAIGN_REVIEW' },
  STORE_CAMPAIGN_EDIT: { name: 'STORE_CAMPAIGN_EDIT' },
  FINANCIAL: { name: 'FINANCIAL' },
  ACTIONS_CENTER: { name: 'ACTIONS_CENTER' },
  BRANCHES: { name: 'BRANCHES' },
  COMPANY_PROFILE: { name: 'STORE_OWNER_COMPANY_PROFILE' },
  HELP: { name: 'STORE_OWNER_HELP' },
  SHARE_FEEDBACK: { name: 'STORE_OWNER_SHARE_FEEDBACK' },
  INCOMING_CAMPAIGN_VIEW: { name: 'INCOMING_CAMPAIGN_VIEW' },
  PLAYLIST: { name: 'PLAYLIST' },
  CREATE_STORE_OWNER_CAMPAIGN: { name: 'CREATE_STORE_OWNER_CAMPAIGN' },
};
